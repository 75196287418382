define("strada-gui/routes/reports/view", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "ember-apollo-client/mixins/route-query-manager", "strada-gui/gql/queries/report"], function (exports, _authenticatedRouteMixin, _routeQueryManager, _report) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _routeQueryManager.default, {
    model: function model(params) {
      var company = this.modelFor("companies.show");
      var variables = { id: params.report_id, company_id: company.id };
      return this.get("apollo").watchQuery({ query: _report.default, variables: variables, fetchPolicy: "no-cache" });
    },


    renderTemplate: function renderTemplate() {
      this.render("reports.view", {
        into: "application"
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var company_id = this.modelFor("companies.show").id;
      var inspection = this.modelFor("inspections.show");

      var breadcrumbs = {
        title: "Rapport bekijken",
        text: model.report.address,
        previous: {
          anchor: "Rapporten",
          route: "reports.index",
          param: inspection.id
        }
      };
      controller.set("currentInspection", inspection);
      controller.set("damages", model.damages);
      controller.set("breadcrumbs", breadcrumbs);
      controller.set("companyId", company_id);
      controller.set("measures", model.measures);
    }
  });
});