define("strada-gui/controllers/users/index", ["exports", "strada-gui/gql/mutations/create-user", "strada-gui/gql/mutations/update-user", "strada-gui/gql/mutations/delete-user", "ember-apollo-client/mixins/route-query-manager"], function (exports, _createUser, _updateUser, _deleteUser, _routeQueryManager) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = Ember.Controller.extend(_routeQueryManager.default, {
    modelSorting: ["name"],
    modelSorted: Ember.computed.sort("model.users", "modelSorting"),
    newDialog: false,
    editDialog: false,
    newErrorMessage: false,
    editErrorMessage: false,
    currentUser: service(),

    roles: Ember.computed("model.users", function () {
      var currentUser = this.get("currentUser");
      var superadmin = currentUser.get("user.role") == "SUPERADMIN";
      var roles = ["USER", "ADMIN", "INSPECTOR"];

      if (superadmin) {
        roles = roles.concat(["SUPERADMIN"]);
      }

      return roles;
    }),

    actions: {
      closeNewDialog: function closeNewDialog() {
        this.set("newDialog", false);
      },
      closeEditDialog: function closeEditDialog() {
        this.set("editDialog", false);
      },
      showNewDialog: function showNewDialog(company) {
        this.set("newDialog", true);
        this.set("newUser", Ember.Object.create({
          name: "",
          email: "",
          companyId: company.id,
          role: "USER"
        }));
      },
      showEditDialog: function showEditDialog(record) {
        this.set("editDialog", true);
        this.set("newUser", Ember.Object.create(record));
      },
      create: function create() {
        var newUser = this.get("newUser");
        var self = this;
        var variables = newUser.getProperties("name", "email", "companyId", "role");
        this.get("apollo").mutate({
          mutation: _createUser.default,
          variables: variables,
          refetchQueries: ["users"]
        }).then(function (data) {
          if (data.createUser.errors != null) {
            if (data.createUser.errors[0] === "email has already been taken") {
              self.set("newErrorMessage", "E-mailadres is al in gebruik");
            } else if (data.createUser.errors[0] === "name can't be blank") {
              self.set("newErrorMessage", "Naam moet ingevuld zijn");
            } else if (data.createUser.errors[0] === "email can't be blank") {
              self.set("newErrorMessage", "E-mailadres moet ingevuld zijn");
            } else {
              self.set("newErrorMessage", data.createUser.errors[0]);
            }
          } else {
            self.set("newErrorMessage", false);
            self.set("newDialog", false);
          }
        }).catch(function (error) {
          self.set("newErrorMessage", error);
        });
      },
      update: function update() {
        var newUser = this.get("newUser");
        var self = this;
        var variables = newUser.getProperties("id", "name", "email", "role", "status");
        this.get("apollo").mutate({
          mutation: _updateUser.default,
          variables: variables,
          refetchQueries: ["users"]
        }).then(function (data) {
          if (data.updateUser.errors != null) {
            if (data.updateUser.errors[0] === "email has already been taken") {
              self.set("editErrorMessage", "E-mailadres is al in gebruik");
            } else if (data.updateUser.errors[0] === "name can't be blank") {
              self.set("editErrorMessage", "Naam moet ingevuld zijn");
            } else if (data.updateUser.errors[0] === "email can't be blank") {
              self.set("editErrorMessage", "E-mailadres moet ingevuld zijn");
            } else {
              self.set("editErrorMessage", data.updateUser.errors[0]);
            }
          } else {
            self.set("editErrorMessage", false);
            self.set("editDialog", false);
          }
        }).catch(function (error) {
          self.set("editErrorMessage", error);
        });
      },
      disableUser: function disableUser(user) {
        var self = this;
        var newValue = user.status == "ACTIVE" ? "DEACTIVATED" : "ACTIVE";
        var variables = {
          id: user.id,
          email: user.email,
          name: user.name,
          role: user.role,
          status: newValue
        };
        this.get("apollo").mutate({
          mutation: _updateUser.default,
          variables: variables,
          refetchQueries: ["users"]
        }).then(function (data) {
          if (data.updateUser.errors != null) {
            self.set("editErrorMessage", data.updateUser.errors[0]);
          } else {
            self.set("editErrorMessage", false);
            self.set("editDialog", false);
          }
        }).catch(function (error) {
          self.set("editErrorMessage", error);
        });
      },
      destroy: function destroy(record) {
        this.set("flashMessage", false);
        var self = this;
        var variables = { id: record.id };
        this.get("apollo").mutate({
          mutation: _deleteUser.default,
          variables: variables,
          refetchQueries: ["users"]
        }).then(function (data) {
          if (data.deleteUser.errors != null) {
            self.set("flashMessage", "Deze gebruiker kan niet worden verwijderd.");
          }
        }).catch(function (error) {
          self.set("editErrorMessage", error);
        });
      }
    }
  });
});