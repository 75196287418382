define('strada-gui/controllers/inspections/map', ['exports', 'ember-apollo-client/mixins/route-query-manager'], function (exports, _routeQueryManager) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_routeQueryManager.default, {
    reportsSorting: ['address'],
    sortedReports: Ember.computed.sort('model.reports', 'reportsSorting'),
    bounds: null,

    boundsComputed: function () {
      var self = this;
      var model = this.get('model.reports');
      var coordinates = [];

      model.forEach(function (item) {
        if (item.latitude && item.longitude) {
          coordinates.push([item.latitude, item.longitude]);
        }
      });

      if (coordinates.length === 0) {
        coordinates.push([53.530986, 7.177372]);
        coordinates.push([50.873502, 3.355695]);
      }

      self.set('bounds', L.latLngBounds(coordinates));
    }.observes('model.reports.@each.latitude', 'model.reports.@each.longitude'),

    actions: {
      goToRoute: function goToRoute(report) {
        var currentInspection = this.get('currentInspection');
        this.transitionToRoute('reports.view', currentInspection.id, report.id);
      }
    }
  });
});