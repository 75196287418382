define('strada-gui/routes/damage/index', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember-apollo-client/mixins/route-query-manager', 'strada-gui/gql/queries/damages'], function (exports, _authenticatedRouteMixin, _routeQueryManager, _damages) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _routeQueryManager.default, {
    model: function model() {
      return this.get('apollo').watchQuery({ query: _damages.default, fetchPolicy: "no-cache" }, "damages");
    },


    renderTemplate: function renderTemplate() {
      this.render('damage.index', {
        into: 'application'
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var breadcrumbs = {
        title: 'Schades'
      };

      controller.set('breadcrumbs', breadcrumbs);
    }
  });
});