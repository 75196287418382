define('strada-gui/routes/inspections/history', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember-apollo-client/mixins/route-query-manager', 'strada-gui/gql/queries/activity-logs', 'strada-gui/gql/queries/inspection', 'moment'], function (exports, _authenticatedRouteMixin, _routeQueryManager, _activityLogs, _inspection, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _routeQueryManager.default, {
    model: function model(params) {
      var inspectionId = params.inspection_id;
      this.set('inspectionId', inspectionId);
      var variables = { inspectionId: inspectionId };
      return this.get('apollo').watchQuery({ query: _activityLogs.default, variables: variables, fetchPolicy: "no-cache" }, 'activityLogs');
    },


    renderTemplate: function renderTemplate() {
      this.render('inspections.history', {
        into: 'application'
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var inspectionId = this.get('inspectionId');
      var variables = { id: inspectionId };

      this.get('apollo').watchQuery({ query: _inspection.default, variables: variables, fetchPolicy: "no-cache" }, "inspection").then(function (inspection) {
        controller.set('currentInspection', inspection);

        var breadcrumbs = {
          title: 'Wijzigingshistorie',
          text: inspection.name,
          previous: {
            anchor: 'Inspecties',
            route: 'inspections'
          }
        };

        controller.set('breadcrumbs', breadcrumbs);
      });

      var dateChunks = [];

      model.forEach(function (entry) {
        var date_divider = (0, _moment.default)(entry.insertedAt).format('YYYY-MM-DD');

        if (!dateChunks[date_divider]) {
          dateChunks[date_divider] = [];
        }
        dateChunks[date_divider].push(entry);
      });
      controller.set('dateChunks', dateChunks);
    }
  });
});