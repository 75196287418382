define('strada-gui/controllers/login', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    session: Ember.inject.service(),
    errorMessage: false,

    actions: {
      authenticate: function authenticate() {
        var self = this;
        self.set('errorMessage', '');

        this.get('session').authenticate('authenticator:custom', this.get('identification'), this.get('password')).then(function () {
          self.transitionToRoute('companies');
        }).catch(function () {
          self.set('errorMessage', 'Je e-mailadres en/of wachtwoord is onjuist');
        });
      }
    }
  });
});