define("strada-gui/components/dashboard-inspections-count", ["exports", "ember-apollo-client/mixins/component-query-manager", "strada-gui/gql/queries/inspection-count"], function (exports, _componentQueryManager, _inspectionCount) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_componentQueryManager.default, {
    init: function init() {
      this._super.apply(this, arguments);

      var variables = { company_id: this.get("company.id") };
      var self = this;
      this.get("apollo").watchQuery({ query: _inspectionCount.default, variables: variables, fetchPolicy: "no-cache" }).then(function (response) {
        self.set("count", response);
      });
    }
  });
});