define('strada-gui/components/report-show-menu', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'ul',
    classNames: 'reports-menu'
  });
});