define("strada-gui/controllers/companies/index", ["exports", "strada-gui/gql/mutations/create-company", "strada-gui/gql/mutations/update-company", "strada-gui/gql/mutations/delete-company", "ember-apollo-client/mixins/route-query-manager"], function (exports, _createCompany, _updateCompany, _deleteCompany, _routeQueryManager) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_routeQueryManager.default, {
    modelSorting: ['name'],
    modelSorted: Ember.computed.sort('model', 'modelSorting'),
    newDialog: false,
    editDialog: false,
    newErrorMessage: false,
    editErrorMessage: false,

    actions: {
      closeNewDialog: function closeNewDialog() {
        this.set('newDialog', false);
      },
      closeEditDialog: function closeEditDialog() {
        this.set('editDialog', false);
      },
      showNewDialog: function showNewDialog() {
        this.set('newDialog', true);
        this.set('newCompany', Ember.Object.create({ name: '' }));
      },
      showEditDialog: function showEditDialog(record) {
        this.set('editDialog', true);
        this.set('newCompany', record);
      },
      create: function create() {
        var newCompany = this.get('newCompany');
        var self = this;

        var variables = newCompany.getProperties('name');
        this.get("apollo").mutate({ mutation: _createCompany.default, variables: variables, refetchQueries: ['companies'] }).then(function () {
          self.set('newErrorMessage', false);
          self.set('newDialog', false);
        }).catch(function (error) {
          self.set('newErrorMessage', error);
        });
      },
      update: function update() {
        var newCompany = this.get('newCompany');
        var self = this;
        this.get("apollo").mutate({ mutation: _updateCompany.default, variables: newCompany, refetchQueries: ['companies'], fetchPolicy: "no-cache" }).then(function () {
          self.set('editErrorMessage', false);
          self.set('editDialog', false);
        }).catch(function (error) {
          self.set('editErrorMessage', error);
        });
      },
      destroy: function destroy(record) {
        this.set('flashMessage', false);
        var self = this;
        var variables = { id: record.id };
        this.get("apollo").mutate({ mutation: _deleteCompany.default, variables: variables, refetchQueries: ['companies'] }).then(function (data) {
          if (data.deleteCompany.errors != null) {
            self.set('flashMessage', 'Deze maatregel wordt gebruikt in een rapport en kan daarom niet worden verwijderd.');
          }
        }).catch(function (error) {
          self.set('editErrorMessage', error);
        });
      },
      destroyLogo: function destroyLogo() {
        var newCompany = this.get('newCompany');
        Ember.set(newCompany, 'logo', null);
        var self = this;
        this.get("apollo").mutate({ mutation: _updateCompany.default, variables: newCompany, refetchQueries: ['companies'] }).then(function () {
          self.set('editErrorMessage', false);
          self.set('editDialog', false);
        }).catch(function (error) {
          self.set('editErrorMessage', error);
        });
      }
    }
  });
});