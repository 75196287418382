define("strada-gui/components/external-link", ["exports", "strada-gui/templates/components/external-link"], function (exports, _externalLink) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _externalLink.default,

    /**
     * Component wrapper attributes.
     *
     * @property attributeBindings
     * @type Array
     */
    attributeBindings: ["href", "target"],

    /**
     * HTML tag.
     *
     * @property tagName
     * @type String
     * @default 'a'
     */
    tagName: "a",

    /**
     * Link href attribute.
     *
     * @property href
     * @type String
     * @default ''
     */
    href: "",

    /**
     * Link target attribute.
     *
     * @property target
     * @type String
     * @default '_blank'
     */
    target: "_blank"
  }).reopenClass({
    positionalParams: ["href"]
  });
});