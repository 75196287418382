define("strada-gui/controllers/measures/index", ["exports", "strada-gui/gql/mutations/create-measure", "strada-gui/gql/mutations/update-measure", "strada-gui/gql/mutations/delete-measure", "ember-apollo-client/mixins/route-query-manager"], function (exports, _createMeasure, _updateMeasure, _deleteMeasure, _routeQueryManager) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_routeQueryManager.default, {
    modelSorting: ['name'],
    modelSorted: Ember.computed.sort('model.measures', 'modelSorting'),
    newDialog: false,
    editDialog: false,
    newErrorMessage: false,
    editErrorMessage: false,
    flashMessage: false,

    actions: {
      closeNewDialog: function closeNewDialog() {
        this.set('newDialog', false);
      },
      closeEditDialog: function closeEditDialog() {
        this.set('editDialog', false);
      },
      showNewDialog: function showNewDialog() {
        this.set('newDialog', true);
        var company = this.get('currentCompany');
        this.set('newMeasure', Ember.Object.create({ name: '', length: false, width: false, surface: false, amount: false,
          hours: false, companyId: company.id, pavementId: null }));
      },
      showEditDialog: function showEditDialog(record) {
        this.set('editDialog', true);
        this.set('newMeasure', Ember.Object.create(record));
      },
      setPavementOnMeasure: function setPavementOnMeasure(pavement) {
        this.set('newMeasure.pavementId', pavement.id);
        this.set('newMeasure.pavement', pavement);
      },
      create: function create() {
        var newMeasure = this.get('newMeasure');
        var self = this;

        var variables = newMeasure.getProperties('name', 'length', 'width', 'surface', 'amount', 'hours', 'companyId', 'pavementId');
        this.get("apollo").mutate({ mutation: _createMeasure.default, variables: variables, refetchQueries: ['measures'] }).then(function () {
          self.set('newErrorMessage', false);
          self.set('newDialog', false);
        }).catch(function (error) {
          self.set('newErrorMessage', error);
        });
      },
      update: function update() {
        var newMeasure = this.get('newMeasure');
        var self = this;
        var variables = newMeasure.getProperties('id', 'name', 'length', 'width', 'surface', 'amount', 'hours', 'pavementId');
        this.get("apollo").mutate({ mutation: _updateMeasure.default, variables: variables, refetchQueries: ['measures'] }).then(function () {
          self.set('editErrorMessage', false);
          self.set('editDialog', false);
        }).catch(function (error) {
          self.set('editErrorMessage', error);
        });
      },
      destroy: function destroy(record) {
        this.set('flashMessage', false);
        var self = this;
        var variables = { id: record.id };
        this.get("apollo").mutate({ mutation: _deleteMeasure.default, variables: variables, refetchQueries: ['measures'] }).then(function (data) {
          if (data.deleteMeasure.errors != null) {
            self.set('flashMessage', 'Deze maatregel wordt gebruikt in een rapport en kan daarom niet worden verwijderd.');
          }
        }).catch(function (error) {
          self.set('editErrorMessage', error);
        });
      },
      clearSelectValue: function clearSelectValue(fieldName) {
        var newMeasure = this.get('newMeasure');

        newMeasure.set(fieldName, null);
      }
    }
  });
});