define("strada-gui/controllers/companies/show", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = Ember.Controller.extend({
    currentUser: service()
  });
});