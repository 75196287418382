define('strada-gui/helpers/role-to-human', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.roleToHuman = roleToHuman;
  function roleToHuman(role) {
    var roleMap = {
      USER: 'Gebruiker',
      INSPECTOR: 'Inspecteur',
      ADMIN: 'Admin',
      SUPERADMIN: 'Super admin'
    };
    return roleMap[role];
  }

  exports.default = Ember.Helper.helper(roleToHuman);
});