define('strada-gui/helpers/plural-helper', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.pluralHelper = pluralHelper;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function pluralHelper(params) {
    var _params = _slicedToArray(params, 2),
        obj = _params[0],
        key = _params[1];

    var body = '';

    if (obj) {
      var objLength = obj.length;
      if (objLength > 0) {

        if (objLength === 1) {
          if (Object.byString(obj[0], key)) {
            body += Object.byString(obj[0], key);
          }
        } else if (objLength === 2) {
          if (Object.byString(obj[objLength - 2], key) && Object.byString(obj[objLength - 1], key)) {
            body += Object.byString(obj[objLength - 2], key) + ' en ';
            body += Object.byString(obj[objLength - 1], key);
          }
        } else if (objLength > 2) {
          var i = void 0;

          for (i = 0; i < objLength - 2; i++) {
            if (Object.byString(obj[i], key)) {
              body += Object.byString(obj[i], key) + ', ';
            }
          }
          if (Object.byString(obj[objLength - 2], key) && Object.byString(obj[objLength - 1], key)) {
            body += Object.byString(obj[objLength - 2], key) + ' en ';
            body += Object.byString(obj[objLength - 1], key);
          }
        }
      }
    }

    return body;
  }

  Object.byString = function (o, s) {
    s = s.replace(/\[(\w+)\]/g, '.$1');
    s = s.replace(/^\./, '');
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
      var k = a[i];
      if (o) {
        if (k in o) {
          o = o[k];
        } else {
          return;
        }
      } else {
        return;
      }
    }
    return o;
  };

  exports.default = Ember.Helper.helper(pluralHelper);
});