define("strada-gui/router", ["exports", "strada-gui/config/environment"], function (exports, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route("users.edit", { path: "users/:user_id/edit" });
    this.route("shared", function () {
      this.route("show", { path: ":inspection_share_id" }, function () {
        this.route("reports", { resetNamespace: true }, function () {
          this.route("show", { path: ":report_id" });
        });
      });
    });

    this.route("companies", { path: "/" }, function () {
      this.route("show", { path: "companies/:company_id" }, function () {
        this.route("projects", { resetNamespace: true }, function () {
          this.route("inbox");
          this.route("outbox");
        });

        this.route("inspections", { resetNamespace: true }, function () {
          this.route("print", { path: ":inspection_id/print" });
          this.route("history", { path: ":inspection_id/history" });
          this.route("map", { path: ":inspection_id/map" });
          this.route("downloads", { path: ":inspection_id/downloads" });
          this.route("show", { path: ":inspection_id" }, function () {
            this.route("reports", { resetNamespace: true }, function () {
              this.route("view", { path: ":report_id" });
              this.route("edit", { path: ":report_id/edit" });
              this.route("history", { path: ":report_id/history" });
              this.route("new");
            });
            this.route("projects", { resetNamespace: true }, function () {
              this.route("show", { path: ":project_id" });
            });
          });
        });
        this.route("users", { resetNamespace: true }, function () {
          this.route("show", { path: ":user_id" });
        });
        this.route("measures", { resetNamespace: true }, function () {
          this.route("show", { path: ":inspection_id" });
        });
      });
    });

    this.route("splash");
    this.route("contractor-projects", { resetNamespace: true }, function () {
      this.route("show", { path: ":contractor_project_id" }, function () {
        this.route("contractor-map", { resetNamespace: true, path: "contractor-map" }, function () {
          this.route("show", { path: ":contractor_report_id" });
        });
        this.route("contractor-reports", { resetNamespace: true }, function () {
          this.route("show", { path: ":contractor_report_id" });
        });
      });
    });

    this.route("damage", { resetNamespace: true }, function () {
      this.route("show", { path: ":damage_id" });
    });
    this.route("road-sections", { resetNamespace: true }, function () {
      this.route("show", { path: ":road_section_id" });
    });
    this.route("pavements", { resetNamespace: true }, function () {
      this.route("show", { path: ":pavement_id" });
    });
    this.route("login");
    this.route("password-reset");
  });

  exports.default = Router;
});