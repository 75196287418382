define("strada-gui/controllers/reports/view", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    currentUser: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.set("terugkoppeling", false);
      this.set("editFeedback", false);
    },


    editMode: Ember.computed("currentUser", "currentInspection", function () {
      var currentInspection = this.get("currentInspection");
      var currentUser = this.get("currentUser");

      if (currentUser.user.role == "INSPECTOR") {
        if (currentInspection.user.id == currentUser.user.id) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }),

    showTerugkoppelen: Ember.computed("terugkoppelen", "model.feedback", function () {
      var terugkoppelen = this.get("terugkoppelen");
      var feedbackModel = this.get("model.feedback");
      return feedbackModel !== null && !terugkoppelen;
    }),

    actions: {
      toggleTerugkoppeling: function toggleTerugkoppeling() {
        var terugkoppeling = this.get("terugkoppeling");
        this.set("terugkoppeling", !terugkoppeling);
      },
      toggleEditFeedback: function toggleEditFeedback() {
        var editFeedback = this.get("editFeedback");
        this.set("editFeedback", !editFeedback);
      }
    }
  });
});