define("strada-gui/helpers/pdf-status-to-human", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.pdfStatusToHuman = pdfStatusToHuman;
  function pdfStatusToHuman(status) {
    var statusMap = {
      FINISHED: "Klaar",
      NEW: "Aangemaakt",
      PROCESSING: "Genereren"
    };
    return statusMap[status];
  }

  exports.default = Ember.Helper.helper(pdfStatusToHuman);
});