define("strada-gui/services/apollo", ["exports", "ember-apollo-client/services/apollo", "apollo-link-context", "strada-gui/config/environment", "apollo-absinthe-upload-link", "apollo-cache-inmemory", "strada-gui/fragmentTypes"], function (exports, _apollo, _apolloLinkContext, _environment, _apolloAbsintheUploadLink, _apolloCacheInmemory, _fragmentTypes) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _apollo.default.extend({
    session: Ember.inject.service(),

    link: Ember.computed(function () {
      var _this = this;

      var httpLink = this._super.apply(this, arguments);
      var uploadLink = (0, _apolloAbsintheUploadLink.createUploadMiddleware)({ uri: _environment.default.apollo.apiURL });
      var authLink = (0, _apolloLinkContext.setContext)(function (request, context) {
        return _this._runAuthorize(request, context);
      });
      authLink = authLink.concat(uploadLink);
      authLink = authLink.concat(httpLink);
      return authLink;
    }),

    _runAuthorize: function _runAuthorize() {
      if (!this.get("session.isAuthenticated")) {
        return {};
      } else {
        var access_token = this.get("session.data.authenticated.token");
        var headers = {};
        headers["Authorization"] = "Bearer " + access_token;
        return { headers: headers };
      }
    },

    cache: Ember.computed(function () {
      return new _apolloCacheInmemory.InMemoryCache({
        fragmentMatcher: new _apolloCacheInmemory.IntrospectionFragmentMatcher({
          introspectionQueryResultData: _fragmentTypes.default
        })
      });
    })
  });
});