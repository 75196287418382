define("strada-gui/controllers/projects/inbox", ["exports", "ember-apollo-client/mixins/route-query-manager"], function (exports, _routeQueryManager) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_routeQueryManager.default, {
    queryParams: ["page", "order", "query"],
    query: "",
    page: 1,
    order: "INSERTED_AT_DESC",

    actions: {
      selectPage: function selectPage(page) {
        window.scroll(0, 0);
        this.set("page", page);
      },
      updateOrder: function updateOrder(newOrder) {
        this.set('order', newOrder);
      }
    }
  });
});