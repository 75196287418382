define('strada-gui/routes/contractor-reports/show', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember-apollo-client/mixins/route-query-manager', 'strada-gui/gql/queries/project-report'], function (exports, _authenticatedRouteMixin, _routeQueryManager, _projectReport) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _routeQueryManager.default, {
    model: function model(params) {
      this.set('params', params);
      var contractorProject = this.modelFor('contractor-projects.show');
      var variables = { id: params.contractor_report_id, project_id: contractorProject.contractor_project_id };
      return this.get('apollo').watchQuery({ query: _projectReport.default, variables: variables, fetchPolicy: "no-cache" });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var report = model.report;
      var breadcrumbs = {
        title: report.address,
        text: 'Rapport',
        previous: {
          anchor: 'Rapporten',
          route: 'contractor-reports.index'
        }
      };

      controller.set('originalFeedbackModel', model.report.feedback);
      controller.set('damages', model.damages);
      controller.set('currentInspection', this.get('params').inspection_id);
      controller.set('breadcrumbs', breadcrumbs);
      model.measures = model.projectMeasures;
      controller.set('measures', model.measures);
    },


    renderTemplate: function renderTemplate() {
      this.render('contractor-reports.show', {
        into: 'application'
      });
    }
  });
});