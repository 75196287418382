define('strada-gui/routes/reports/history', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember-apollo-client/mixins/route-query-manager', 'strada-gui/gql/queries/activity-logs', 'strada-gui/gql/queries/report', 'moment'], function (exports, _authenticatedRouteMixin, _routeQueryManager, _activityLogs, _report, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _routeQueryManager.default, {
    model: function model(params) {
      this.set('params', params);
      var inspection = this.modelFor('inspections.show');
      var inspectionId = inspection.id;
      this.set('inspectionId', inspectionId);
      var variables = { inspectionId: inspectionId };
      return this.get('apollo').watchQuery({ query: _activityLogs.default, variables: variables, fetchPolicy: "no-cache" }, 'activityLogs');
    },


    renderTemplate: function renderTemplate() {
      this.render('reports.history', {
        into: 'application'
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var company = this.modelFor('companies.show');
      var params = this.get('params');
      controller.set('currentInspectionId', this.get('inspectionId'));
      var variables = { id: params.report_id, company_id: company.id };

      this.get('apollo').watchQuery({ query: _report.default, variables: variables, fetchPolicy: "no-cache" }).then(function (report) {
        var breadcrumbs = {
          title: 'Wijzigingshistorie',
          text: report.report.address,
          previous: {
            anchor: 'Rapporten',
            route: 'reports.index'
          }
        };

        var dateChunks = [];

        model.forEach(function (entry) {
          var date_divider = (0, _moment.default)(entry.report.insertedAt).format('YYYY-MM-DD');

          if (!dateChunks[date_divider]) {
            dateChunks[date_divider] = [];
          }
          dateChunks[date_divider].push(entry);
        });

        controller.set('report', report);
        controller.set('dateChunks', dateChunks);
        controller.set('breadcrumbs', breadcrumbs);
      });
    }
  });
});