define('strada-gui/routes/index', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      return this.store.findAll('company', { reload: true });
    },


    afterModel: function afterModel(companies) {
      if (companies.get('length') === 1) {
        this.transitionTo('companies.show', companies.get('firstObject'));
      }
    }
  });
});