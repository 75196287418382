define("strada-gui/controllers/users/edit", ["exports", "strada-gui/gql/mutations/update-current-user", "strada-gui/gql/queries/me", "ember-apollo-client/mixins/route-query-manager"], function (exports, _updateCurrentUser, _me, _routeQueryManager) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = Ember.Controller.extend(_routeQueryManager.default, {
    currentUser: service(),

    actions: {
      saveChanges: function saveChanges() {
        var self = this;
        var variables = this.get('model').getProperties('name', 'email', 'password');
        var currentUser = this.get('currentUser');
        this.get("apollo").mutate({ mutation: _updateCurrentUser.default, variables: variables, refetchQueries: ['users'] }).then(function (data) {
          if (data.updateCurrentUser.errors != null) {
            if (data.updateCurrentUser.errors[0] === "email has already been taken") {
              self.set('editErrorMessage', "E-mailadres is al in gebruik");
            } else if (data.updateCurrentUser.errors[0] === "name can't be blank") {
              self.set('editErrorMessage', "Naam moet ingevuld zijn");
            } else if (data.updateCurrentUser.errors[0] === "email can't be blank") {
              self.set('editErrorMessage', "E-mailadres moet ingevuld zijn");
            } else if (data.updateCurrentUser.errors[0] === "password should be at least 8 character(s)") {
              self.set('editErrorMessage', "Password moet minstens 8 tekens lang zijn");
            } else {
              self.set('editErrorMessage', data.updateCurrentUser.errors[0]);
            }
          } else {
            if (currentUser.get('user.id') == self.get('model.id')) {
              self.get("apollo").query({ query: _me.default, fetchPolicy: "no-cache" }, 'me').then(function (me) {
                self.set('currentUser.user.name', me.name);
                self.set('editErrorMessage', false);
                self.set('editDialog', false);
                self.transitionToRoute('companies');
              });
            } else {
              self.set('editErrorMessage', false);
              self.set('editDialog', false);
              self.transitionToRoute('companies');
            }
          }
        }).catch(function (error) {
          self.set('editErrorMessage', error);
        });
      }
    }
  });
});