define('strada-gui/controllers/contractor-reports/index', ['exports', 'ember-apollo-client/mixins/route-query-manager'], function (exports, _routeQueryManager) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_routeQueryManager.default, {
    defaultSorting: ['name'],
    reportsSorting: ['position:asc'],
    sortedReports: Ember.computed.sort('reports', 'reportsSorting'),
    queryParams: ['query', 'roadSectionId', 'pavementId', 'measureId', 'damageId', 'status'],
    query: '',
    pavementId: '',
    measureId: '',
    roadSectionId: '',
    damageId: '',
    status: '',

    actions: {
      updateDamageId: function updateDamageId(damageId) {
        this.set('damageId', damageId);
      },
      updateMeasureId: function updateMeasureId(measureId) {
        this.set('measureId', measureId);
      },
      updatePavementId: function updatePavementId(pavementId) {
        this.set('pavementId', pavementId);
      },
      updateRoadSectionId: function updateRoadSectionId(roadSectionId) {
        this.set('roadSectionId', roadSectionId);
      },
      toggleFilterStatus: function toggleFilterStatus(clickStatus) {
        var status = this.get('status');

        if (clickStatus === status) {
          this.set('status', null);
        } else {
          this.set('status', clickStatus);
        }
      },
      transitionToDetailView: function transitionToDetailView(record) {
        this.transitionToRoute('contractor-reports.show', record.id);
      }
    }
  });
});