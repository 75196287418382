define("strada-gui/routes/projects/index", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "ember-apollo-client/mixins/route-query-manager", "strada-gui/gql/queries/projects-outbox"], function (exports, _authenticatedRouteMixin, _routeQueryManager, _projectsOutbox) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _routeQueryManager.default, {
    model: function model(params) {
      var company = this.modelFor("companies.show");
      var inspection = this.modelFor("inspections.show");
      this.set("company", company);
      this.set("inspection", inspection);

      var variables = {
        list_projects: {
          company_id: company.id,
          inspection_id: inspection.id,
          order_by: "INSERTED_AT_DESC"
        },
        pagination: { page: params.page, page_size: 10 }
      };

      return this.get("apollo").watchQuery({ query: _projectsOutbox.default, variables: variables, fetchPolicy: "no-cache" }, "projectsOutbox");
    },


    queryParams: {
      page: { refreshModel: true }
    },

    renderTemplate: function renderTemplate() {
      this.render("projects.index", {
        into: "application"
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller);
      controller.set("projects", model);
      var inspection = this.get("inspection");
      controller.set("currentInspection", inspection);
      var breadcrumbs = {
        title: "Opdrachten",
        text: inspection.name,
        previous: {
          anchor: "Inspecties",
          route: "inspections"
        }
      };

      controller.set("breadcrumbs", breadcrumbs);
    }
  });
});