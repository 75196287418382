define('strada-gui/routes/inspections/print', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember-apollo-client/mixins/route-query-manager', 'strada-gui/gql/queries/inspection-print'], function (exports, _authenticatedRouteMixin, _routeQueryManager, _inspectionPrint) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _routeQueryManager.default, {
    model: function model(params) {
      var reportIds = params.uuids.split(',');
      var variables = { id: params.inspection_id, report_ids: reportIds };
      Object.keys(variables).forEach(function (key) {
        if (variables[key] == '') {
          delete variables[key];
        }
      });
      return this.get('apollo').watchQuery({ query: _inspectionPrint.default, variables: variables, fetchPolicy: 'no-cache' });
    },


    renderTemplate: function renderTemplate() {
      this.render('inspections.print', {
        into: 'application'
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('reports', model.reports);
      controller.set('currentInspection', model.inspection);

      var breadcrumbs = {
        title: 'Print',
        text: model.inspection.name,
        previous: {
          anchor: 'Inspecties',
          route: 'inspections'
        }
      };

      controller.set('breadcrumbs', breadcrumbs);
    }
  });
});