define('strada-gui/routes/inspections/show', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember-apollo-client/mixins/route-query-manager', 'strada-gui/gql/queries/inspection'], function (exports, _authenticatedRouteMixin, _routeQueryManager, _inspection) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _routeQueryManager.default, {
    model: function model(params) {
      var variables = { id: params.inspection_id };
      return this.get('apollo').watchQuery({ query: _inspection.default, variables: variables, fetchPolicy: "no-cache" }, 'inspection');
    },


    renderTemplate: function renderTemplate() {
      this.render('inspections.show', {
        into: 'application'
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
    }
  });
});