define('strada-gui/routes/contractor-map/index', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember-apollo-client/mixins/route-query-manager', 'strada-gui/gql/queries/project-reports-map'], function (exports, _authenticatedRouteMixin, _routeQueryManager, _projectReportsMap) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _routeQueryManager.default, {
    model: function model() {
      var contractorProject = this.modelFor('contractor-projects.show');
      var contractorProjectId = contractorProject.contractor_project_id;
      this.set('contractorProjectId', contractorProjectId);
      var variables = { project_id: contractorProjectId };
      return this.get('apollo').watchQuery({ query: _projectReportsMap.default, variables: variables, fetchPolicy: "no-cache" });
    },


    renderTemplate: function renderTemplate() {
      this.render('contractor-map.index', {
        into: 'application'
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('currentProjectId', this.get('contractorProjectId'));

      var currentInspection = void 0;
      if (model.projectReports.length) {
        currentInspection = model.projectReports.firstObject.project.inspection;
        controller.set('currentInspection', currentInspection);
      }

      var breadcrumbs = {
        title: 'Rapporten',
        text: currentInspection ? currentInspection.name : '',
        previous: {
          anchor: 'Opdrachten',
          route: 'contractor-projects'
        }
      };
      controller.set('breadcrumbs', breadcrumbs);
    }
  });
});