define("strada-gui/components/page-pagination", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    showPagination: Ember.computed.or("meta.{nextPage,previousPage}"),
    hasPrevious: Ember.computed.readOnly("meta.previousPage"),
    hasNext: Ember.computed.readOnly("meta.nextPage"),
    disabledPrevious: Ember.computed.not("hasPrevious"),
    disabledNext: Ember.computed.not("hasNext"),

    actions: {
      goToNextPage: function goToNextPage() {
        if (!this.meta.nextPage) return;
        this.onSelectPage(this.meta.nextPage);
      },
      goToPreviousPage: function goToPreviousPage() {
        if (!this.meta.previousPage) return;
        this.onSelectPage(this.meta.previousPage);
      }
    }
  });
});