define("strada-gui/routes/inspections/downloads", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "ember-apollo-client/mixins/route-query-manager", "strada-gui/gql/queries/inspection", "strada-gui/gql/queries/pdfs"], function (exports, _authenticatedRouteMixin, _routeQueryManager, _inspection, _pdfs) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _routeQueryManager.default, {
    model: function model(params) {
      var variables = { id: params.inspection_id };
      return this.get("apollo").watchQuery({ query: _inspection.default, variables: variables, fetchPolicy: "no-cache" }, "inspection");
    },


    renderTemplate: function renderTemplate() {
      this.render("inspections.downloads", {
        into: "application"
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set("currentInspection", model);
      var variables = { inspectionId: model.id };

      this.get("apollo").watchQuery({ query: _pdfs.default, variables: variables, fetchPolicy: "no-cache", pollInterval: 1000 }, "pdfs").then(function (pdfs) {
        controller.set("pdfs", pdfs);
      });

      var breadcrumbs = {
        title: "Prints",
        text: model.name,
        previous: {
          anchor: "Inspecties",
          route: "inspections"
        }
      };

      controller.set("breadcrumbs", breadcrumbs);
    }
  });
});