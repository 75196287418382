define("strada-gui/routes/reports/index", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "ember-apollo-client/mixins/route-query-manager", "strada-gui/gql/queries/reports-index"], function (exports, _authenticatedRouteMixin, _routeQueryManager, _reportsIndex) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _routeQueryManager.default, {
    queryParams: {
      pavementId: { refreshModel: true },
      roadSectionId: { refreshModel: true },
      damageId: { refreshModel: true },
      measureId: { refreshModel: true },
      status: { refreshModel: true },
      query: { refreshModel: true }
    },

    model: function model(params) {
      this.set("params", params);
      var inspection = this.modelFor("inspections.show");
      var company = this.modelFor("companies.show");
      this.set("company", company);
      var variables = {
        inspection_id: inspection.id,
        measure_id: params.measureId,
        company_id: company.id,
        pavement_id: params.pavementId,
        road_section_id: params.roadSectionId,
        damage_id: params.damageId,
        status: params.status,
        query: params.query
      };
      for (var key in variables) {
        if (variables[key] == "") delete variables[key];
      }

      return this.get("apollo").watchQuery({
        query: _reportsIndex.default,
        variables: variables,
        fetchPolicy: "no-cache"
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var inspection = this.modelFor("inspections.show");
      var company = this.modelFor("companies.show");
      controller.set("currentInspection", inspection);
      controller.set("currentCompany", company);
      controller.set("damages", model.damages);
      controller.set("roadsections", model.roadSections);
      controller.set("pavements", model.pavements);
      controller.set("allChecked", false);

      var params = this.get("params");
      controller.set("filterActive", params["roadSectionId"] !== "" || params["pavementId"] !== "" || params["status"] !== null);

      var breadcrumbs = {
        title: "Rapporten",
        text: inspection.name,
        previous: {
          anchor: "Inspecties",
          route: "inspections"
        }
      };

      controller.set("breadcrumbs", breadcrumbs);
    },


    resetController: function resetController(controller) {
      var queryParams = controller.get("queryParams");
      queryParams.forEach(function (param) {
        controller.set(param, null);
      });
    },

    renderTemplate: function renderTemplate() {
      this.render("reports.index", {
        into: "application"
      });
    }
  });
});