define("strada-gui/routes/projects/outbox", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "ember-apollo-client/mixins/route-query-manager", "strada-gui/gql/queries/projects-outbox"], function (exports, _authenticatedRouteMixin, _routeQueryManager, _projectsOutbox) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _routeQueryManager.default, {
    model: function model(params) {
      var company = this.modelFor("companies.show");
      this.set("company", company);

      var variables = {
        list_projects: {
          company_id: company.id,
          order_by: params.order,
          query: params.query
        },
        pagination: { page: params.page, page_size: 10 }
      };

      return this.get("apollo").watchQuery({ query: _projectsOutbox.default, variables: variables, fetchPolicy: "no-cache" }, "projectsOutbox");
    },

    queryParams: {
      page: { refreshModel: true },
      order: { refreshModel: true },
      query: { refreshModel: true }
    },
    renderTemplate: function renderTemplate() {
      this.render("projects.outbox", {
        into: "application"
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('company', this.get('company'));
    }
  });
});