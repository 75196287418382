define('strada-gui/controllers/inspections/print', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    reportsSorting: ['position:asc'],
    sortedReports: Ember.computed.sort('reports', 'reportsSorting'),
    queryParams: ['uuids'],

    filters: Ember.computed('pavement.name', 'measurement.name', 'roadSection.name', 'damage.name', function () {
      var result = [this.get('pavement.name'), this.get('measurement.name'), this.get('roadSection.name'), this.get('damage.name')];
      return result.compact().join(', ');
    })
  });
});