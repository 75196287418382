define('strada-gui/controllers/reports/history', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    // init() {
    //   this._super(...arguments);
    //   this.set('terugkoppelen', false);
    // },
    //
    // showTerugkoppelen: Ember.computed('terugkoppelen', 'model.feedback', function() {
    //   let terugkoppelen = this.get('terugkoppelen');
    //   let feedbackModel = this.get('model.feedback');
    //   return feedbackModel !== null && !terugkoppelen;
    // }),
    //
    // actions: {
    //   toggleTerugkoppelen() {
    //     let terugkoppelen = this.get('terugkoppelen');
    //     this.set('terugkoppelen', !terugkoppelen);
    //   }
    // }
  });
});