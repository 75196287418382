define('strada-gui/controllers/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = Ember.Controller.extend({
    session: service(),
    leftSideBarOpen: false,
    currentUser: service(),

    actions: {
      invalidateSession: function invalidateSession() {
        this.get('session').invalidate();
      },
      transition: function transition(route, model) {
        this.transitionToRoute(route, model);
      },
      toggle: function toggle() {
        this.toggleProperty('leftSideBarOpen');
      }
    }
  });
});