define("strada-gui/controllers/password-reset", ["exports", "strada-gui/gql/mutations/reset", "ember-apollo-client/mixins/route-query-manager"], function (exports, _reset, _routeQueryManager) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_routeQueryManager.default, {
    disabled: false,
    error: false,

    actions: {
      create: function create() {
        var self = this;
        var variables = this.get('model').getProperties('email');
        this.get("apollo").mutate({ mutation: _reset.default, variables: variables }).then(function () {
          self.set('errorMessage', 'Als dit e-mailadres bestaat is er zojuist een nieuw wachtwoord naartoe verstuurd.');
          self.set('disabled', true);
        }).catch(function () {
          self.set('errorMessage', 'Er ging iets mis');
        });
      }
    }
  });
});