define("strada-gui/gql/queries/reports-csv", ["exports", "strada-gui/gql/fragments/report-fragment", "strada-gui/gql/fragments/pavement-fragment", "strada-gui/gql/fragments/damage-fragment", "strada-gui/gql/fragments/feedback-fragment"], function (exports, _reportFragment, _pavementFragment, _damageFragment, _feedbackFragment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var doc = {
    "kind": "Document",
    "definitions": [{
      "kind": "OperationDefinition",
      "operation": "query",
      "name": {
        "kind": "Name",
        "value": "reportsCsv"
      },
      "variableDefinitions": [{
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "inspection_id"
          }
        },
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "ID"
            }
          }
        }
      }, {
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "measure_id"
          }
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }, {
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "pavement_id"
          }
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }, {
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "road_section_id"
          }
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }, {
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "damage_id"
          }
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "ID"
          }
        }
      }, {
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "status"
          }
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }, {
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "query"
          }
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      }],
      "directives": [],
      "selectionSet": {
        "kind": "SelectionSet",
        "selections": [{
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "reports"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "inspectionId"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "inspection_id"
              }
            }
          }, {
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "measureId"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "measure_id"
              }
            }
          }, {
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "pavementId"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "pavement_id"
              }
            }
          }, {
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "roadSectionId"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "road_section_id"
              }
            }
          }, {
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "damageId"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "damage_id"
              }
            }
          }, {
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "status"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "status"
              }
            }
          }, {
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "query"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "query"
              }
            }
          }],
          "directives": [],
          "selectionSet": {
            "kind": "SelectionSet",
            "selections": [{
              "kind": "FragmentSpread",
              "name": {
                "kind": "Name",
                "value": "ReportFragment"
              },
              "directives": []
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "city"
              },
              "arguments": [],
              "directives": []
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "latitude"
              },
              "arguments": [],
              "directives": []
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "longitude"
              },
              "arguments": [],
              "directives": []
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "note"
              },
              "arguments": [],
              "directives": []
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "user"
              },
              "arguments": [],
              "directives": [],
              "selectionSet": {
                "kind": "SelectionSet",
                "selections": [{
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "id"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "name"
                  },
                  "arguments": [],
                  "directives": []
                }]
              }
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "roadSection"
              },
              "arguments": [],
              "directives": [],
              "selectionSet": {
                "kind": "SelectionSet",
                "selections": [{
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "id"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "name"
                  },
                  "arguments": [],
                  "directives": []
                }]
              }
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "pavement"
              },
              "arguments": [],
              "directives": [],
              "selectionSet": {
                "kind": "SelectionSet",
                "selections": [{
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "id"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "name"
                  },
                  "arguments": [],
                  "directives": []
                }]
              }
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "damage"
              },
              "arguments": [],
              "directives": [],
              "selectionSet": {
                "kind": "SelectionSet",
                "selections": [{
                  "kind": "FragmentSpread",
                  "name": {
                    "kind": "Name",
                    "value": "DamageFragment"
                  },
                  "directives": []
                }]
              }
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "feedback"
              },
              "arguments": [],
              "directives": [],
              "selectionSet": {
                "kind": "SelectionSet",
                "selections": [{
                  "kind": "FragmentSpread",
                  "name": {
                    "kind": "Name",
                    "value": "FeedbackFragment"
                  },
                  "directives": []
                }]
              }
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "pictures"
              },
              "arguments": [],
              "directives": [],
              "selectionSet": {
                "kind": "SelectionSet",
                "selections": [{
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "id"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "fileUrl"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "fileThumbUrl"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "insertedAt"
                  },
                  "arguments": [],
                  "directives": []
                }]
              }
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "insertedAt"
              },
              "arguments": [],
              "directives": []
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "project"
              },
              "arguments": [],
              "directives": [],
              "selectionSet": {
                "kind": "SelectionSet",
                "selections": [{
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "user"
                  },
                  "arguments": [],
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [{
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "id"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "name"
                      },
                      "arguments": [],
                      "directives": []
                    }]
                  }
                }]
              }
            }]
          }
        }, {
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "damages"
          },
          "arguments": [],
          "directives": [],
          "selectionSet": {
            "kind": "SelectionSet",
            "selections": [{
              "kind": "FragmentSpread",
              "name": {
                "kind": "Name",
                "value": "DamageFragment"
              },
              "directives": []
            }]
          }
        }, {
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "roadSections"
          },
          "arguments": [],
          "directives": [],
          "selectionSet": {
            "kind": "SelectionSet",
            "selections": [{
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "id"
              },
              "arguments": [],
              "directives": []
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "name"
              },
              "arguments": [],
              "directives": []
            }]
          }
        }, {
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "pavements"
          },
          "arguments": [],
          "directives": [],
          "selectionSet": {
            "kind": "SelectionSet",
            "selections": [{
              "kind": "FragmentSpread",
              "name": {
                "kind": "Name",
                "value": "PavementFragment"
              },
              "directives": []
            }]
          }
        }]
      }
    }],
    "loc": {
      "start": 0,
      "end": 1120
    }
  };
  exports.default = doc;

  doc.definitions = doc.definitions.concat(_reportFragment.default.definitions);

  doc.definitions = doc.definitions.concat(_pavementFragment.default.definitions);

  doc.definitions = doc.definitions.concat(_damageFragment.default.definitions);

  doc.definitions = doc.definitions.concat(_feedbackFragment.default.definitions);
});