define("strada-gui/components/feedback-measures", ["exports", "ember-apollo-client/mixins/component-query-manager"], function (exports, _componentQueryManager) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Component.extend(_componentQueryManager.default, {
    classNames: ["feedback-measures"],

    init: function init() {
      this._super.apply(this, arguments);
      this.set("currentIndex", false);
      this.set("newDialog", false);
      this.set("editDialog", false);
    },


    actions: {
      closeNewDialog: function closeNewDialog() {
        this.set("newDialog", false);
      },
      closeEditDialog: function closeEditDialog() {
        this.set("editDialog", false);
      },
      showEditDialog: function showEditDialog(index) {
        var feedbackMeasures = this.get("feedbackModel.feedbackMeasures");
        var feedbackMeasure = feedbackMeasures[index];

        this.set("currentIndex", index);
        this.set("editModel", feedbackMeasure);
        this.set("editDialog", true);
      },
      showNewDialog: function showNewDialog() {
        var feedbackModel = this.get("feedbackModel");
        this.set("newModel", { feedbackId: feedbackModel.id });
        this.set("newDialog", true);
      },
      setMeasure: function setMeasure(value) {
        var newDialog = this.get("newDialog");
        var objectName = newDialog ? "newModel" : "editModel";
        this.set(objectName + ".measure", value);
        this.set(objectName + ".measureId", value.id);
      },
      create: function create() {
        var variables = this.get("newModel");
        variables = convertFloats(variables, ["amount", "length", "hours", "surface", "width"]);

        if (variables.feedbackId) delete variables.feedbackId;
        var feedbackMeasures = this.get("feedbackModel.feedbackMeasures");

        feedbackMeasures.pushObject(variables);
        this.set("feedbackModel.feedbackMeasures", feedbackMeasures);
        this.send("closeNewDialog");
      },
      delete: function _delete(index) {
        var feedbackMeasures = this.get("feedbackModel.feedbackMeasures");
        feedbackMeasures.removeAt(index);
        this.set("feedbackModel.feedbackMeasures", feedbackMeasures);
        var editModel = this.get("editModel");
        if (editModel) {
          this.set("editDialog", false);
        }
      },
      update: function update() {
        var editModel = Ember.Object.create(this.get("editModel"));
        editModel = convertFloats(editModel, ["amount", "length", "hours", "surface", "width"]);
        var feedbackMeasures = this.get("feedbackModel.feedbackMeasures");
        feedbackMeasures[this.get("currentIndex")] = editModel;
        this.set("feedbackModel.feedbackMeasures", feedbackMeasures);
        this.set("editModel", editModel);
        this.send("closeEditDialog");
      }
    }
  });


  function convertFloats(object, properties) {
    var floats = Ember.getProperties(object, properties);

    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = Object.entries(floats)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var _ref = _step.value;

        var _ref2 = _slicedToArray(_ref, 2);

        var key = _ref2[0];
        var value = _ref2[1];

        var parsedValue = parseFloat(value);
        floats[key] = isNaN(parsedValue) ? null : parsedValue;
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    return Object.assign(object, floats);
  }
});