define("strada-gui/routes/inspections/index", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "ember-apollo-client/mixins/route-query-manager", "strada-gui/gql/queries/inspections"], function (exports, _authenticatedRouteMixin, _routeQueryManager, _inspections) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _routeQueryManager.default, {
    model: function model(params) {
      var company_id = this.modelFor("companies.show").id;
      var variables = {
        list_inspections: {
          company_id: company_id,
          order_by: params.order,
          query: params.query,
          status: params.status
        },
        pagination: { page: params.page, page_size: 20 }
      };
      return this.get("apollo").watchQuery({ query: _inspections.default, variables: variables, fetchPolicy: "no-cache" }, "inspections");
    },


    queryParams: {
      page: { refreshModel: true },
      query: { refreshModel: true },
      status: { refreshModel: true },
      order: { refreshModel: true }
    },

    renderTemplate: function renderTemplate() {
      this.render("inspections.index", {
        into: "application"
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set("currentCompany", this.modelFor("companies.show"));

      var breadcrumbs = {
        title: "Inspecties"
      };

      controller.set("breadcrumbs", breadcrumbs);
    }
  });
});