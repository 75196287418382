define('strada-gui/initializers/browser/leaflet-assets', ['exports', 'ember-get-config'], function (exports, _emberGetConfig) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  var isNone = Ember.isNone;
  function initialize() /* container, application */{
    var prefix = '';

    if (!isNone(_emberGetConfig.default.rootURL)) {
      prefix = _emberGetConfig.default.rootURL;
    } else if (!isNone(_emberGetConfig.default.baseURL)) {
      prefix = _emberGetConfig.default.baseURL;
    }

    L.Icon.Default.imagePath = prefix + 'assets/images/';
  }

  exports.default = {
    name: 'leaflet-assets',
    initialize: initialize
  };
});