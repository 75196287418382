define('strada-gui/components/marker-layer-component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    location: Ember.computed('report.latitude', 'report.longitude', function () {
      return [this.get('report').latitude, this.get('report').longitude];
    }),

    divIcon: Ember.computed('location', function () {
      return '<div class="hint--top hint--rounded" aria-label="' + this.get('report').address + '"><div class="icon-marker"></div><div class=\'position\'>' + this.get('report').position + '</div></div>';
    }),

    actions: {
      goToRoute: function goToRoute(report) {
        this.goToRoute(report);
      }
    }
  });
});