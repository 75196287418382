define("strada-gui/helpers/inspection-status-to-human", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.inspectionStatusToHuman = inspectionStatusToHuman;
  function inspectionStatusToHuman(status) {
    var statusMap = {
      DRAFT: "Inspecteren",
      OPEN: "Open",
      ASSIGNED: "In uitvoering",
      SOLVED: "Afgerond",
      ARCHIVED: "Archief"
    };
    return statusMap[status];
  }

  exports.default = Ember.Helper.helper(inspectionStatusToHuman);
});