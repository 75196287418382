define("strada-gui/routes/users/index", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "ember-apollo-client/mixins/route-query-manager", "strada-gui/gql/queries/users"], function (exports, _authenticatedRouteMixin, _routeQueryManager, _users) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _routeQueryManager.default, {
    breadCrumb: { title: "Gebruikers", path: "users.index" },

    model: function model() {
      var company_id = this.modelFor("companies.show").id;
      var variables = { company_id: company_id };
      return this.get("apollo").watchQuery({ query: _users.default, variables: variables, fetchPolicy: "no-cache" });
    },


    renderTemplate: function renderTemplate() {
      this.render("users.index", {
        into: "application"
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set("currentCompany", this.modelFor("companies.show"));

      var breadcrumbs = {
        title: "Gebruikers"
      };

      controller.set("breadcrumbs", breadcrumbs);
    }
  });
});