define('strada-gui/routes/shared/show/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    breadCrumb: {},

    afterModel: function afterModel(model) {
      var crumb = { inspectionShow: true, title: model.inspection.name, status: model.status, path: 'shared.show' };
      this.set('breadCrumb', crumb);
    }
  });
});