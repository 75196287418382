define('strada-gui/routes/users/edit', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember-apollo-client/mixins/route-query-manager', 'strada-gui/gql/queries/me'], function (exports, _authenticatedRouteMixin, _routeQueryManager, _me) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _routeQueryManager.default, {
    breadCrumb: { title: 'Gegevens', path: 'users.edit' },

    model: function model() {
      return this.get('apollo').watchQuery({ query: _me.default, fetchPolicy: "no-cache" }, 'me');
    }
  });
});