define("strada-gui/components/reports-table", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["report-table"],
    defaultSorting: ["name"],
    reportsSorting: ["position:desc"],
    sortedReports: Ember.computed.sort("reports.reports", "reportsSorting"),
    sortedPavements: Ember.computed.sort("reports.pavements", "defaultSorting"),
    sortedRoadSections: Ember.computed.sort("reports.roadSections", "defaultSorting"),
    sortedDamages: Ember.computed.sort("reports.damages", "defaultSorting"),
    sortedMeasures: Ember.computed.sort("reports.measures", "defaultSorting"),

    actions: {
      updateDamageId: function updateDamageId(damageId) {
        this.updateDamageId(damageId);
      },
      updatePavementId: function updatePavementId(pavementId) {
        this.updatePavementId(pavementId);
      },
      updateRoadSectionId: function updateRoadSectionId(roadSectionId) {
        this.updateRoadSectionId(roadSectionId);
      },
      updateMeasureId: function updateMeasureId(measureId) {
        this.updateMeasureId(measureId);
      },
      toggleFilterStatus: function toggleFilterStatus(value) {
        this.toggleFilterStatus(value);
      },
      transitionToDetailView: function transitionToDetailView(record) {
        this.transitionToDetailView(record);
      },


      // editMode specific
      changeAllChecked: function changeAllChecked(value) {
        this.changeAllChecked(value);
      },
      update: function update(value) {
        this.update(value);
      },
      multipleDelete: function multipleDelete(value) {
        this.multipleDelete(value);
      },
      multiplePrint: function multiplePrint(value) {
        this.multiplePrint(value);
      },
      multiplePdf: function multiplePdf(value) {
        this.multiplePdf(value);
      },
      multipleCSV: function multipleCSV(value) {
        this.multipleCSV(value);
      },
      multipleUpdate: function multipleUpdate(value) {
        this.multipleUpdate(value);
      },
      toggleCreateProjectDialog: function toggleCreateProjectDialog(value) {
        this.toggleCreateProjectDialog(value);
      },
      toggleCreateInspectionShareDialog: function toggleCreateInspectionShareDialog(value) {
        this.toggleCreateInspectionShareDialog(value);
      }
    }
  });
});