define("strada-gui/components/dashboard-projects-outbox", ["exports", "ember-apollo-client/mixins/component-query-manager", "strada-gui/gql/queries/projects-outbox"], function (exports, _componentQueryManager, _projectsOutbox) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = Ember.Component.extend(_componentQueryManager.default, {
    currentUser: service(),

    init: function init() {
      this._super.apply(this, arguments);

      var variables = {
        list_projects: {
          company_id: this.get("company.id"),
          order_by: "INSERTED_AT_DESC"
        },
        pagination: { page: 1, page_size: 10 }
      };
      var self = this;
      if (this.company.id === this.currentUser.user.company.id) {
        this.get("apollo").watchQuery({ query: _projectsOutbox.default, variables: variables, fetchPolicy: "no-cache" }).then(function (response) {
          self.set("projects", response);
        });
      }
    }
  });
});