define('strada-gui/components/print-component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    partition: function partition(array, n) {
      array = array.slice();
      return function _partition() {
        return array.length ? [array.splice(0, n)].concat(_partition()) : [];
      }();
    },


    inhoudsopgaveAmount: Ember.computed('reports.each', function () {
      var reports = this.get('reports');
      var reportLength = reports.get('length');
      var inhoudsopgaveAmount = Math.ceil(reportLength / 23);
      return inhoudsopgaveAmount;
    }),

    inhoudsopgaves: Ember.computed('inhoudsopgaveAmount', function () {
      var inhoudsopgaveAmount = this.get('inhoudsopgaveAmount');
      var times = parseInt(inhoudsopgaveAmount);
      var reports = this.get('reports');
      var partition = this.partition(reports, 23);
      var inhoudsopgaves = [];

      for (var i = 0; i < times; i++) {
        inhoudsopgaves[i] = partition[i];
      }

      return inhoudsopgaves;
    })
  });
});