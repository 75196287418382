define("strada-gui/components/report-feedback", ["exports", "ember-apollo-client/mixins/component-query-manager", "strada-gui/gql/mutations/create-feedback", "strada-gui/gql/mutations/create-feedback-picture", "strada-gui/gql/mutations/delete-feedback-picture", "strada-gui/gql/mutations/update-feedback", "strada-gui/gql/queries/report-without-measures"], function (exports, _componentQueryManager, _createFeedback, _createFeedbackPicture, _deleteFeedbackPicture, _updateFeedback, _reportWithoutMeasures) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Component.extend(_componentQueryManager.default, {
    init: function init() {
      this._super.apply(this, arguments);
      var reportModel = this.get("reportModel.report");
      var feedbackModel = this.get("reportModel.report.feedback") ? this.get("reportModel.report.feedback") : Ember.Object.create({
        reportId: reportModel.id,
        feedbackMeasures: [],
        feedbackPictures: []
      });

      this.set("feedbackModel", feedbackModel);
      this.set("lastFeedbackId", null);
    },


    disabled: Ember.computed("editMode", function () {
      var editMode = this.get("editMode");
      return !editMode;
    }),

    actions: {
      setDamage: function setDamage(value) {
        this.set("feedbackModel.damage", value);
        this.set("feedbackModel.damageId", value.id);
      },
      setEditMode: function setEditMode() {
        this.set("editMode", true);
      },
      create: function create() {
        var feedback = this.get("feedbackModel");
        var variables = Ember.getProperties(feedback, "reportId", "work", "material", "note", "damageId");
        var self = this;

        feedback.feedbackMeasures.forEach(function (feedbackMeasure) {
          delete feedbackMeasure.measure;
        });

        feedback.feedbackMeasures = convertFloats(feedback.feedbackMeasures, ["amount", "length", "hours", "surface", "width"]);

        var refetchQueries = ["report", {
          query: _reportWithoutMeasures.default,
          variables: { id: feedback.reportId },
          fetchPolicy: "no-cache"
        }, "reports-csv"];

        this.get("apollo").mutate({
          mutation: _createFeedback.default,
          variables: variables,
          refetchQueries: refetchQueries
        }).then(function (response) {
          self.set("lastFeedbackId", response.createFeedback.feedback.id);
          self.send("upload", self.get("feedbackModel"), response.createFeedback.feedback.id);
          self.set("editMode", false);
        });
      },
      update: function update() {
        var feedbackModel = Ember.Object.create(this.get("reportModel.report.feedback"));
        var reportModel = this.get("reportModel");
        var reportId = reportModel.report.id;
        var note = feedbackModel.note;


        var self = this;
        delete feedbackModel.report;
        delete feedbackModel.damage;
        if (!feedbackModel.id) {
          feedbackModel.id = this.get("lastFeedbackId");
        }
        feedbackModel.feedbackMeasures.forEach(function (feedbackMeasure) {
          if (feedbackMeasure.measure && feedbackMeasure.measure.id) {
            feedbackMeasure.measureId = feedbackMeasure.measure.id;
          }

          delete feedbackMeasure.measure;
          delete feedbackMeasure.insertedAt;
        });

        var variables = { id: reportId };
        var refetchQueries = ["report", {
          query: _reportWithoutMeasures.default,
          variables: { id: reportId },
          fetchPolicy: "no-cache"
        }, "reports-csv"];

        this.get("apollo").watchQuery({ query: _reportWithoutMeasures.default, variables: variables, fetchPolicy: "no-cache" }, "report").then(function (response) {
          var originalFeedbackModel = response.feedback;

          feedbackModel.reportId = reportId;
          var variables = feedbackModel;
          variables.feedbackMeasures = convertFloats(variables.feedbackMeasures, ["amount", "length", "hours", "surface", "width"]);
          variables.feedbackMeasures.forEach(function (feedbackMeasure) {
            delete feedbackMeasure["__typename"];
            for (var key in feedbackMeasure) {
              if (feedbackMeasure[key] == null) delete feedbackMeasure[key];
            }
          });

          variables.note = note;

          self.get("apollo").mutate({
            mutation: _updateFeedback.default,
            variables: variables,
            refetchQueries: refetchQueries
          }).then(function () {
            originalFeedbackModel.feedbackPictures.forEach(function (feedbackPicture) {
              var found = feedbackModel.feedbackPictures.find(function (item) {
                return item.id == feedbackPicture.id;
              });

              if (!found) {
                var _variables = { id: feedbackPicture.id };
                self.get("apollo").mutate({
                  mutation: _deleteFeedbackPicture.default,
                  variables: _variables,
                  refetchQueries: refetchQueries
                });
              }
            });

            self.send("upload", feedbackModel, feedbackModel.id);
            self.set("editMode", false);
          });
        });
      },
      setFeedbackPictures: function setFeedbackPictures(feedbackPictures) {
        this.set("feedbackModel.feedbackPictures", feedbackPictures);
      },
      setFeedbackMeasures: function setFeedbackMeasures(feedbackMeasures) {
        this.set("feedbackModel.feedbackMeasures", feedbackMeasures);
      },
      upload: function upload(feedback, feedbackId) {
        var reportModel = this.get("reportModel");
        var reportId = reportModel.report.id;
        var self = this;
        var refetchQueries = ["report", {
          query: _reportWithoutMeasures.default,
          variables: { id: reportId },
          fetchPolicy: "no-cache"
        }];

        feedback.feedbackPictures.forEach(function (feedbackPicture) {
          if (feedbackPicture.temp) {
            urltoFile(feedbackPicture.fileThumbUrl, feedbackPicture.fileName).then(function (file) {
              var variables = { feedbackId: feedbackId, file: file };

              self.get("apollo").mutate({
                mutation: _createFeedbackPicture.default,
                variables: variables,
                refetchQueries: refetchQueries
              }).then(function () {
                delete feedbackPicture.temp;
                delete feedbackPicture.fileName;
              });
            });
          }
        });
      }
    }
  });


  //return a promise that resolves with a File instance
  function urltoFile(url, filename, mimeType) {
    mimeType = mimeType || (url.match(/^data:([^;]+);/) || "")[1];
    return fetch(url).then(function (res) {
      return res.arrayBuffer();
    }).then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
  }

  function convertFloats(object, properties) {
    var floats = Ember.getProperties(object, properties);

    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = Object.entries(floats)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var _ref = _step.value;

        var _ref2 = _slicedToArray(_ref, 2);

        var key = _ref2[0];
        var value = _ref2[1];

        var parsedValue = parseFloat(value);
        floats[key] = isNaN(parsedValue) ? null : parsedValue;
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    return Object.assign(object, floats);
  }
});