define("strada-gui/components/calculate-results", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "",

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var key = this.get("key");
      var value = this.get("value");
      var model = this.get("model").reports;
      var results = void 0;

      if (key == "measure") {
        results = model.filter(function (result) {
          if (result.reportsMeasures) {
            return result.reportsMeasures.find(function (a) {
              if (a.measure) {
                return a.measure.id == value;
              }
            });
          }
        });
      } else {
        results = model.filter(function (result) {
          if (result[key]) {
            return result[key].id == value;
          }
        });
      }

      this.set("resultsAmount", results.length);
    }
  });
});