define('strada-gui/components/report-information', ['exports', 'ember-apollo-client/mixins/component-query-manager'], function (exports, _componentQueryManager) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_componentQueryManager.default, {
    classNames: ['report-information'],

    init: function init() {
      this._super.apply(this, arguments);
      var editMode = this.get('editMode');
      this.set('disabled', !editMode);
    },
    getAddressComponent: function getAddressComponent(data, name) {
      var address = data['results'][0]['address_components'];
      var result = address.find(function (component) {
        return component.types.includes(name);
      });

      return result ? result.long_name : null;
    },


    actions: {
      setPictures: function setPictures(pictures) {
        this.setPictures(pictures);
      },


      // edit mode
      setRelation: function setRelation(name, value) {
        this.set('model.' + name, value);
        this.set('model.' + name + 'Id', value.id);
      },
      setRelationRoadSection: function setRelationRoadSection(value) {
        this.send('setRelation', 'roadSection', value);
      },
      setRelationDamage: function setRelationDamage(value) {
        this.send('setRelation', 'damage', value);
      },
      setRelationMeasure: function setRelationMeasure(value) {
        this.send('setRelation', 'measure', value);
      },
      setRelationPavement: function setRelationPavement(value) {
        this.send('setRelation', 'pavement', value);
      },
      setLocation: function setLocation(city, address) {
        this.set('model.city', city);
        this.set('model.address', address);
      },
      clearSelectValue: function clearSelectValue(fieldName) {
        this.set('model.' + fieldName, null);
        this.set('model.' + fieldName + 'Id', null);
      },
      addReportMutation: function addReportMutation() {
        var reportsMeasures = this.get('model.reportsMeasures');
        reportsMeasures.pushObject({ surface: null, width: null, length: null, hours: null, amount: null, measure: null });
      },
      removeReportMutation: function removeReportMutation(index) {
        var reportsMeasures = this.get('model.reportsMeasures');
        reportsMeasures.removeAt(index);
      },
      performGeocode: function performGeocode() {
        var r = confirm("Weet u zeker dat u door wilt gaan? Wanneer u op 'OK' klikt worden de latitude en longitude opnieuw gegenereerd op basis van het opgegeven adres.");
        if (r === true) {
          var model = this.get('model');
          var query = model.address + ', ' + model.city;
          var countrycodes = 'nl';
          var format = 'json';
          var self = this;
          var requestEndpoint = 'https://nominatim.openstreetmap.org/search/';

          Ember.$.ajax({ type: "GET", url: requestEndpoint, data: { q: query, format: format, countrycodes: countrycodes }
          }).then(function (data) {
            self.set('model.latitude', data[0]['lat']);
            self.set('model.longitude', data[0]['lon']);
          });
        }
      }
    }
  });
});