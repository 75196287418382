define('strada-gui/routes/inspections', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var breadcrumbs = {
        title: 'Inspecties'
      };

      controller.set('breadcrumbs', breadcrumbs);
    }
  });
});