define("strada-gui/helpers/sum", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.sum = sum;
  function sum(params /*, hash*/) {
    var sum = 0;
    params.forEach(function (param) {
      sum += param;
    });
    return sum;
  }

  exports.default = Ember.Helper.helper(sum);
});