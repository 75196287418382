define('strada-gui/services/current-user', ['exports', 'strada-gui/gql/queries/me'], function (exports, _me) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      RSVP = Ember.RSVP;
  exports.default = Ember.Service.extend({
    session: service(),
    apollo: service(),
    user: null,

    load: function load() {
      if (this.get('session.isAuthenticated')) {
        var self = this;
        return this.get('apollo').watchQuery({ query: _me.default, fetchPolicy: "no-cache" }, "me").then(function (data) {
          self.set('user', Ember.Object.create(data));
        });
      } else {
        return RSVP.resolve();
      }
    }
  });
});