define('strada-gui/routes/reports/new', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember-apollo-client/mixins/route-query-manager', 'strada-gui/gql/queries/report-new'], function (exports, _authenticatedRouteMixin, _routeQueryManager, _reportNew) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _routeQueryManager.default, {
    model: function model() {
      return Ember.Object.create({ reportsMeasures: [], pictures: [] });
    },


    renderTemplate: function renderTemplate() {
      this.render('reports.new', {
        into: 'application'
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var inspection = this.modelFor('inspections.show');
      var company_id = this.modelFor('companies.show').id;
      controller.set('inspection', inspection);
      controller.set('companyId', company_id);
      var company = this.modelFor('companies.show');
      var variables = { company_id: company.id };
      this.get('apollo').watchQuery({ query: _reportNew.default, variables: variables, fetchPolicy: "no-cache" }).then(function (data) {
        controller.set('meta', data);
      });
      var breadcrumbs = {
        title: 'Nieuw rapport',
        text: inspection.name,
        previous: {
          anchor: 'Rapporten',
          route: 'reports.index'
        }
      };

      controller.set('breadcrumbs', breadcrumbs);
    }
  });
});