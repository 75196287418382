define('strada-gui/controllers/projects/show', ['exports', 'ember-apollo-client/mixins/route-query-manager'], function (exports, _routeQueryManager) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_routeQueryManager.default, {
    reportsSorting: ['address'],
    sortedReports: Ember.computed.sort('reports', 'reportsSorting')
  });
});