define("strada-gui/helpers/external-contact-typename-to-human", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.externalContactTypenameToHuman = externalContactTypenameToHuman;
  function externalContactTypenameToHuman(typename) {
    var statusMap = {
      ExternalContactProject: "Project",
      ExternalContactShare: "Inspectie gedeeld"
    };
    return statusMap[typename];
  }

  exports.default = Ember.Helper.helper(externalContactTypenameToHuman);
});