define('strada-gui/controllers/contractor-reports/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.set('terugkoppelen', false);
    },


    showTerugkoppelen: Ember.computed('terugkoppelen', 'feedbackModel', function () {
      var terugkoppelen = this.get('terugkoppelen');
      var feedbackModel = this.get('feedbackModel');
      return !terugkoppelen && !feedbackModel;
    }),

    feedbackModel: Ember.computed('model.feedback.[]', function () {
      var model = this.get('model');
      return model.get('feedback');
    }),
    actions: {
      toggleTerugkoppelen: function toggleTerugkoppelen() {
        var terugkoppelen = this.get('terugkoppelen');
        this.set('terugkoppelen', !terugkoppelen);
      }
    }
  });
});