define("strada-gui/routes/companies/show", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "ember-apollo-client/mixins/route-query-manager", "strada-gui/gql/queries/company"], function (exports, _authenticatedRouteMixin, _routeQueryManager, _company) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _routeQueryManager.default, {
    classNames: ["fase3"],

    model: function model(params) {
      var variables = { id: params.company_id };
      return this.get("apollo").watchQuery({ query: _company.default, variables: variables, fetchPolicy: "no-cache" }, "company");
    },


    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.controllerFor("application").set("currentCompany", model);
    }
  });
});