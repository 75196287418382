define("strada-gui/controllers/reports/edit", ["exports", "strada-gui/gql/mutations/update-report", "strada-gui/gql/queries/report-without-measures", "strada-gui/gql/queries/reports-index", "strada-gui/gql/mutations/delete-report", "strada-gui/gql/mutations/delete-picture", "ember-apollo-client/mixins/route-query-manager", "strada-gui/gql/mutations/create-picture"], function (exports, _updateReport, _reportWithoutMeasures, _reportsIndex, _deleteReport, _deletePicture, _routeQueryManager, _createPicture) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Controller.extend(_routeQueryManager.default, {
    disabled: Ember.computed('model.report.city', 'model.report.address', 'model.report.longitude', 'model.report.latitude', function () {
      var city = this.get('model.report.city');
      var address = this.get('model.report.address');
      var longitude = this.get('model.report.longitude');
      var latitude = this.get('model.report.latitude');
      return !city || !address || !longitude || !latitude;
    }),

    actions: {
      saveReport: function saveReport(report) {
        var self = this;
        var rVariables = Ember.getProperties(report, 'id', 'address', 'city', 'note', 'inspectionId', 'roadSectionId', 'pavementId', 'damageId');
        var floats = Ember.getProperties(report, 'latitude', 'longitude');
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = Object.entries(floats)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var _ref = _step.value;

            var _ref2 = _slicedToArray(_ref, 2);

            var key = _ref2[0];
            var value = _ref2[1];

            var parsedValue = parseFloat(value);
            floats[key] = isNaN(parsedValue) ? null : parsedValue;
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        rVariables = Object.assign(rVariables, floats);

        var measures = [];
        for (var i = 0, len = report.reportsMeasures.length; i < len; i++) {
          var reportMeasure = report.reportsMeasures[i];
          var reportMeasureVariables = Ember.getProperties(reportMeasure, 'surface', 'width', 'length', 'amount', 'hours');
          var _iteratorNormalCompletion2 = true;
          var _didIteratorError2 = false;
          var _iteratorError2 = undefined;

          try {
            for (var _iterator2 = Object.entries(reportMeasureVariables)[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
              var _ref3 = _step2.value;

              var _ref4 = _slicedToArray(_ref3, 2);

              var _key = _ref4[0];
              var _value = _ref4[1];

              var parsedValue = parseFloat(_value);
              reportMeasureVariables[_key] = isNaN(parsedValue) ? null : parsedValue;
            }
          } catch (err) {
            _didIteratorError2 = true;
            _iteratorError2 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion2 && _iterator2.return) {
                _iterator2.return();
              }
            } finally {
              if (_didIteratorError2) {
                throw _iteratorError2;
              }
            }
          }

          if (reportMeasure.id) reportMeasureVariables['id'] = reportMeasure.id;
          reportMeasureVariables['measureId'] = reportMeasure.measure.id;

          measures.pushObject(reportMeasureVariables);
        }
        var reportId = report.id;
        var variables = { id: reportId };

        this.get('apollo').watchQuery({ query: _reportWithoutMeasures.default, variables: variables, fetchPolicy: "no-cache" }, "report").then(function (response) {
          var originalModel = response;
          variables = rVariables;
          variables['reportsMeasures'] = measures;
          var refetchQueries = ['reports', { query: _reportWithoutMeasures.default, variables: { id: variables.id }, fetchPolicy: "no-cache" }];
          self.get("apollo").mutate({ mutation: _updateReport.default, variables: variables, refetchQueries: refetchQueries }).then(function () {
            originalModel.pictures.forEach(function (picture) {
              var found = report.pictures.find(function (item) {
                return item.id == picture.id;
              });

              if (!found) {
                var _variables = { id: picture.id };
                self.get("apollo").mutate({ mutation: _deletePicture.default, variables: _variables, refetchQueries: ['report', 'reports-csv'] });
              }
            });

            var refetchQueries = ['report', { query: _reportWithoutMeasures.default, variables: { id: reportId }, fetchPolicy: "no-cache" }];

            report.pictures.forEach(function (picture) {
              if (picture.temp) {
                urltoFile(picture.fileThumbUrl, picture.fileName).then(function (file) {
                  var variables = { reportId: reportId, file: file };

                  self.get("apollo").mutate({ mutation: _createPicture.default, variables: variables, refetchQueries: refetchQueries }).then(function () {
                    delete picture.temp;
                    delete picture.fileName;
                  });
                });
              }
            });

            self.transitionToRoute('reports.view', variables.id);
          }).catch(function (error) {
            self.set('newErrorMessage', error);
          });
        });
      },
      setPictures: function setPictures(pictures) {
        this.set('model.report.pictures', pictures);
      },
      destroyReport: function destroyReport(report) {
        var self = this;
        var answer = confirm("Weet je zeker dat je dit rapport wilt verwijderen?");
        var company_id = this.get("companyId");

        if (answer) {
          var refetchQueries = ['reports-index', { query: _reportsIndex.default, variables: { inspection_id: report.inspectionId, company_id: company_id }, fetchPolicy: "no-cache" }];
          var variables = { id: report.id };

          self.get("apollo").mutate({ mutation: _deleteReport.default, variables: variables, refetchQueries: refetchQueries }).then(function () {
            self.transitionToRoute('reports.index');
          });
        }
      }
    }
  });


  function urltoFile(url, filename, mimeType) {
    mimeType = mimeType || (url.match(/^data:([^;]+);/) || '')[1];
    return fetch(url).then(function (res) {
      return res.arrayBuffer();
    }).then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
  }
});