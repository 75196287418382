define("strada-gui/controllers/projects/index", ["exports", "strada-gui/gql/mutations/delete-project", "ember-apollo-client/mixins/route-query-manager"], function (exports, _deleteProject, _routeQueryManager) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_routeQueryManager.default, {
    queryParams: ["page"],
    page: 1,

    actions: {
      deleteProject: function deleteProject(project_id) {
        var variables = { id: project_id };
        this.get("apollo").mutate({
          mutation: _deleteProject.default,
          variables: variables,
          refetchQueries: ["projects"]
        });
      },
      selectPage: function selectPage(page) {
        window.scroll(0, 0);
        this.set("page", page);
      }
    }
  });
});