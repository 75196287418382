define("strada-gui/controllers/reports/new", ["exports", "strada-gui/gql/mutations/create-report", "strada-gui/gql/queries/report", "strada-gui/gql/queries/reports-index", "ember-apollo-client/mixins/route-query-manager", "strada-gui/gql/mutations/create-picture"], function (exports, _createReport, _report, _reportsIndex, _routeQueryManager, _createPicture) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Controller.extend(_routeQueryManager.default, {
    disabled: Ember.computed('model.city', 'model.address', 'model.longitude', 'model.latitude', function () {
      var city = this.get('model.city');
      var address = this.get('model.address');
      var longitude = this.get('model.longitude');
      var latitude = this.get('model.latitude');
      return !city || !address || !longitude || !latitude;
    }),

    actions: {
      saveReport: function saveReport(report) {
        var self = this;
        var inspection_id = this.get('inspection').id;
        var company_id = this.get('companyId');
        var variables = report.getProperties('address', 'city', 'note', 'inspectionId', 'roadSectionId', 'pavementId', 'measureId', 'damageId');
        var floats = report.getProperties('latitude', 'longitude');
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = Object.entries(floats)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var _ref = _step.value;

            var _ref2 = _slicedToArray(_ref, 2);

            var key = _ref2[0];
            var value = _ref2[1];

            var parsedValue = parseFloat(value);
            floats[key] = isNaN(parsedValue) ? null : parsedValue;
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        variables = Object.assign(variables, floats);
        variables['inspectionId'] = inspection_id;

        var measures = [];
        for (var i = 0, len = report.reportsMeasures.length; i < len; i++) {
          var reportMeasure = report.reportsMeasures[i];
          var reportMeasureVariables = Ember.getProperties(reportMeasure, 'surface', 'width', 'length', 'amount', 'hours');
          var _iteratorNormalCompletion2 = true;
          var _didIteratorError2 = false;
          var _iteratorError2 = undefined;

          try {
            for (var _iterator2 = Object.entries(reportMeasureVariables)[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
              var _ref3 = _step2.value;

              var _ref4 = _slicedToArray(_ref3, 2);

              var _key = _ref4[0];
              var _value = _ref4[1];

              var parsedValue = parseFloat(_value);
              reportMeasureVariables[_key] = isNaN(parsedValue) ? null : parsedValue;
            }
          } catch (err) {
            _didIteratorError2 = true;
            _iteratorError2 = err;
          } finally {
            try {
              if (!_iteratorNormalCompletion2 && _iterator2.return) {
                _iterator2.return();
              }
            } finally {
              if (_didIteratorError2) {
                throw _iteratorError2;
              }
            }
          }

          reportMeasureVariables['measureId'] = reportMeasure.measure.id;
          measures.pushObject(reportMeasureVariables);
        }
        variables['reportsMeasures'] = measures;
        var refetchQueries = ['reports-index', { query: _reportsIndex.default, variables: { inspection_id: inspection_id, company_id: company_id }, fetchPolicy: "no-cache" }];
        this.get("apollo").mutate({ mutation: _createReport.default, variables: variables, refetchQueries: refetchQueries }, 'createReport').then(function (data) {
          var reportId = data.report.id;

          var model = self.get('model');
          var refetchQueries = ['report', { query: _report.default, variables: { id: reportId }, fetchPolicy: "no-cache" }];

          model.pictures.forEach(function (picture) {
            if (picture.temp) {
              urltoFile(picture.fileThumbUrl, picture.fileName).then(function (file) {
                var variables = { reportId: reportId, file: file };

                self.get("apollo").mutate({ mutation: _createPicture.default, variables: variables, refetchQueries: refetchQueries }).then(function () {
                  delete picture.temp;
                  delete picture.fileName;
                });
              });
            }
          });

          self.transitionToRoute('reports.view', data.report.id);
        }).catch(function (error) {
          self.set('newErrorMessage', error);
        });
      },
      setPictures: function setPictures(pictures) {
        this.set('model.pictures', pictures);
      }
    }
  });


  function urltoFile(url, filename, mimeType) {
    mimeType = mimeType || (url.match(/^data:([^;]+);/) || '')[1];
    return fetch(url).then(function (res) {
      return res.arrayBuffer();
    }).then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
  }
});