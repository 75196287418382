define("strada-gui/app", ["exports", "strada-gui/resolver", "ember-load-initializers", "strada-gui/config/environment", "@sentry/browser", "@sentry/integrations"], function (exports, _resolver, _emberLoadInitializers, _environment, _browser, _integrations) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  if (_environment.default.sentryDSN != null) {
    _browser.init({
      dsn: _environment.default.sentryDSN,
      integrations: [new _integrations.Ember()],
      beforeSend: function beforeSend(event) {
        // ignore ember errors
        if (event.exception.values[0].type == "TransitionAborted") {
          return null;
        }
        return event;
      },

      release: _environment.default.sentryRelease,
      environment: _environment.default.sentryEnvironment
    });
  }

  var App = void 0;
  App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);

  exports.default = App;
});