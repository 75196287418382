define("strada-gui/routes/projects/inbox", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "ember-apollo-client/mixins/route-query-manager", "strada-gui/gql/queries/projects-inbox"], function (exports, _authenticatedRouteMixin, _routeQueryManager, _projectsInbox) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _routeQueryManager.default, {
    currentUser: service(),

    model: function model(params) {
      var company = this.modelFor("companies.show");
      this.set('company', company);
      if (company.id === this.currentUser.user.company.id) {
        var variables = {
          list_projects: { order_by: params.order, query: params.query },
          pagination: { page: params.page, page_size: 10 }
        };
        return this.get("apollo").watchQuery({ query: _projectsInbox.default, variables: variables, fetchPolicy: "no-cache" }, "projectsInbox");
      } else {
        return {
          data: {
            projectsInbox: {
              __typename: "PaginatedProjects",
              entries: [],
              pagination: {
                __typename: "Pagination",
                currentPage: 1,
                nextPage: null,
                previousPage: null,
                totalPages: 1
              }
            }
          }
        };
      }
    },


    queryParams: {
      page: { refreshModel: true },
      order: { refreshModel: true },
      query: { refreshModel: true }
    },

    renderTemplate: function renderTemplate() {
      this.render("projects.inbox", {
        into: "application"
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('company', this.get('company'));
    }
  });
});