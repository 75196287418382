define('strada-gui/components/upload-feedback-picture', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      create: function create(event) {
        var file = event.target.files[0];
        var fileName = file.name;
        var self = this;
        var reader = new FileReader();

        reader.onload = function (e) {
          self.create(e.target.result, fileName);
        };

        reader.readAsDataURL(event.target.files[0]);
      }
    }
  });
});