define('strada-gui/routes/reports/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      var inspectionShare = this.modelFor('shared.show');
      return inspectionShare.reports.filterBy('id', params.report_id).get('firstObject');
    },


    renderTemplate: function renderTemplate() {
      this.render('reports.show', {
        into: 'application'
      });
    },

    afterModel: function afterModel(model) {
      var crumb = { reportEdit: true, address: model.address, status: model.status };
      this.set('breadCrumb', crumb);
    }
  });
});