define("strada-gui/components/upload-avatar", ["exports", "strada-gui/gql/mutations/upload-company", "ember-apollo-client/mixins/component-query-manager"], function (exports, _uploadCompany, _componentQueryManager) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_componentQueryManager.default, {
    actions: {
      upload: function upload(event) {
        var id = this.get('id');
        var logo = event.target.files[0];
        var variables = { id: id, logo: logo };
        var self = this;
        this.get("apollo").mutate({ mutation: _uploadCompany.default, variables: variables, refetchQueries: ['companies'] }).then(function () {
          event.target.value = '';
          self.get('onUploadFinished')();
        });
      }
    }
  });
});