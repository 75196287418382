define("strada-gui/controllers/reports/index", ["exports", "strada-gui/gql/mutations/update-report", "strada-gui/gql/mutations/create-pdf", "strada-gui/gql/mutations/delete-report", "strada-gui/gql/mutations/create-project", "strada-gui/gql/mutations/create-inspection-share", "ember-apollo-client/mixins/route-query-manager", "strada-gui/gql/queries/reports-csv", "strada-gui/gql/queries/reports-index", "strada-gui/gql/queries/pdfs", "strada-gui/gql/queries/projects-outbox"], function (exports, _updateReport, _createPdf, _deleteReport, _createProject, _createInspectionShare, _routeQueryManager, _reportsCsv, _reportsIndex, _pdfs, _projectsOutbox) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_routeQueryManager.default, {
    currentUser: Ember.inject.service(),
    allChecked: false,
    defaultSorting: ["name"],
    sortedPavements: Ember.computed.sort("pavements", "defaultSorting"),
    sortedMeasures: Ember.computed.sort("measures", "defaultSorting"),
    sortedRoadSections: Ember.computed.sort("roadsections", "defaultSorting"),
    sortedDamages: Ember.computed.sort("damages", "defaultSorting"),
    reportsSorting: ["position:asc"],
    sortedReports: Ember.computed.sort("model.reports", "reportsSorting"),
    sortedAndFilteredReports: Ember.computed.filterBy("sortedReports", "checked", true),
    checkedUuids: Ember.computed.filterBy("model.reports", "checked", true),
    shareDialog: false,
    projectDialog: false,
    filterActive: true,
    recipientName: null,
    recipientEmail: null,
    errorMessage: false,
    successMessage: false,
    showShareButton: true,

    queryParams: ["query", "roadSectionId", "pavementId", "damageId", "measureId", "status"],
    pavementId: "",
    measureId: "",
    roadSectionId: "",
    damageId: "",
    query: "",
    status: "",

    editMode: Ember.computed("currentUser", "currentInspection", function () {
      var currentInspection = this.get("currentInspection");
      var currentUser = this.get("currentUser");

      if (currentUser.user.role == "INSPECTOR") {
        if (currentInspection.user.id == currentUser.user.id) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }),

    _allChecked: Ember.computed("allChecked", function () {
      return this.get("status") == "open";
    }),

    openFilterStatus: Ember.computed("status", function () {
      return this.get("status") == "open";
    }),

    solvedFilterStatus: Ember.computed("status", function () {
      return this.get("status") == "solved";
    }),

    ids: Ember.computed("checkedUuids", function () {
      var checkedUuids = this.get("checkedUuids");
      var mapping = checkedUuids.mapBy("id");

      return mapping.join();
    }),

    calculateChecked: function calculateChecked(shouldAllBeChecked) {
      this.get("model.reports").forEach(function (report) {
        Ember.set(report, "checked", shouldAllBeChecked);
      });
    },

    actions: {
      changeAllChecked: function changeAllChecked(value) {
        this.set("allChecked", value);
        this.calculateChecked(value);
      },
      toggleCreateProjectDialog: function toggleCreateProjectDialog() {
        this.toggleProperty("projectDialog");
      },
      toggleCreateInspectionShareDialog: function toggleCreateInspectionShareDialog() {
        this.toggleProperty("shareDialog");
      },
      toggleFilterStatus: function toggleFilterStatus(clickStatus) {
        this.set("allChecked", false);
        this.calculateChecked(false);
        var status = this.get("status");

        if (clickStatus === status) {
          this.set("status", null);
        } else {
          this.set("status", clickStatus);
        }
      },
      createProject: function createProject() {
        var self = this;
        var currentInspection = this.get("currentInspection");
        var reports = this.get("sortedAndFilteredReports");
        var reportIds = this.get("checkedUuids").mapBy("id");
        var variables = {
          reportIds: reportIds,
          inspectionId: currentInspection.id,
          name: this.get("recipientName"),
          email: this.get("recipientEmail")
        };

        var refetchQueries = ["projectsOutbox", {
          query: _projectsOutbox.default,
          variables: {
            list_projects: {
              company_id: this.get("currentCompany.id"),
              inspection_id: currentInspection.id,
              order_by: "INSERTED_AT_DESC"
            },
            pagination: { page: 1, page_size: 10 }
          }
        }, "inspections"];

        this.get("apollo").mutate({ mutation: _createProject.default, variables: variables, refetchQueries: refetchQueries }).then(function () {
          self.set("successMessage", "De opdracht is succesvol verzonden");
          self.set("errorMessage", false);

          setTimeout(function () {
            self.set("successMessage", false);
            self.set("recipientName", "");
            self.set("recipientEmail", "");
            self.set("projectDialog", false);

            reports.forEach(function (report) {
              report.checked = false;
            });
          }, 1000);
        }).catch(function () {
          self.set("errorMessage", "Er ging iets fout");
        });
      },
      createInspectionShare: function createInspectionShare() {
        var self = this;
        var currentInspection = this.get("currentInspection");
        var reports = this.get("sortedAndFilteredReports");
        var reportIds = this.get("checkedUuids").mapBy("id");
        var variables = {
          reportIds: reportIds,
          inspectionId: currentInspection.id,
          recipient: this.get("recipientEmail")
        };

        this.get("apollo").mutate({ mutation: _createInspectionShare.default, variables: variables }).then(function () {
          self.set("successMessage", "De rapporten zijn succesvol gedeeld");
          self.set("errorMessage", false);

          setTimeout(function () {
            self.set("successMessage", false);
            self.set("recipientEmail", "");
            self.set("shareDialog", false);

            reports.forEach(function (report) {
              report.checked = false;
            });
          }, 1000);
        }).catch(function () {
          self.set("errorMessage", "Er ging iets fout");
        });
      },
      toggleMapView: function toggleMapView() {
        this.toggleProperty("mapView");
      },
      updateDamageId: function updateDamageId(damageId) {
        this.set("allChecked", false);
        this.calculateChecked(false);
        this.set("damageId", damageId);
      },
      updatePavementId: function updatePavementId(pavementId) {
        this.set("allChecked", false);
        this.calculateChecked(false);
        this.set("pavementId", pavementId);
      },
      updateMeasureId: function updateMeasureId(measureId) {
        this.set("allChecked", false);
        this.calculateChecked(false);
        this.set("measureId", measureId);
      },
      updateRoadSectionId: function updateRoadSectionId(roadSectionId) {
        this.set("allChecked", false);
        this.calculateChecked(false);
        this.set("roadSectionId", roadSectionId);
      },
      multiplePrint: function multiplePrint() {
        var checkedUuids = this.get("checkedUuids");
        var currentInspection = this.get("currentInspection");
        var uuids = checkedUuids.mapBy("id");

        this.transitionToRoute("inspections.print", currentInspection.id, {
          queryParams: { uuids: uuids }
        });
      },
      multiplePdf: function multiplePdf() {
        var self = this;
        var currentInspection = this.get("currentInspection");
        var reportIds = this.get("checkedUuids").mapBy("id");

        var variables = {
          input: {
            reportIds: reportIds,
            inspectionId: currentInspection.id
          }
        };

        var refetchQueries = ["pdfs", {
          query: _pdfs.default,
          variables: { inspectionId: currentInspection.id }
        }];

        this.get("apollo").mutate({ mutation: _createPdf.default, variables: variables, refetchQueries: refetchQueries }).then(function () {
          self.set("errorMessage", false);
          self.transitionToRoute("inspections.downloads", currentInspection.id);
        }).catch(function () {
          self.set("errorMessage", "Er ging iets fout");
        });
      },
      multipleCSV: function multipleCSV() {
        var currentInspection = this.get("currentInspection");
        var selectedReportIds = this.get("checkedUuids").mapBy("id");
        var variables = { inspection_id: currentInspection.id };

        this.get("apollo").watchQuery({ query: _reportsCsv.default, variables: variables, fetchPolicy: "no-cache" }, "reports").then(function (reports) {
          var selectedReports = reports.filter(function (item) {
            return selectedReportIds.includes(item.id);
          });
          var csvFileContents = "";
          csvFileContents += "Rapport;;;;;;;;;;;;;;;;;;;;Terugkoppeling;;;;;;;;;;;;\n";
          csvFileContents += "Id;Nr;Toegevoegd op;Adres;Stad;Latitude;Longitude;Notitie;Status;Inspecteur;Wegvakonderdelen;Verhardingen;Schade;Aantal afbeeldingen;Maatregelen;Oppervlakte;Lengte;Breedte;Hoeveelheid;Uren;Behandelaar;Materiaal;Notitie;Uren;Schade;Aantal afbeeldingen;Maatregelen;Oppervlakte;Lengte;Breedte;Hoeveelheid;Uren\n";
          selectedReports.forEach(function (report) {
            var feedback = report.feedback;
            var userName = report.user ? report.user.name : "";
            var roadSectionName = report.roadSection ? report.roadSection.name : "";
            var pavementName = report.pavement ? report.pavement.name : "";
            var damageName = report.damage ? report.damage.name : "";
            csvFileContents += report.id + ";" + report.position + ";" + report.insertedAt + ";" + report.address + ";" + report.city + ";" + report.latitude + ";" + report.longitude + ";" + (report.note != null ? report.note.replace(/(\r\n\t|\n|\r\t)/gm, "") : "") + ";" + report.status + ";" + userName + ";" + roadSectionName + ";" + pavementName + ";" + damageName + ";";

            csvFileContents += report.pictures.length + ";";
            csvFileContents += ";";

            csvFileContents += ";;;;;";

            if (feedback) {
              csvFileContents += feedback.user.name + ";" + feedback.material + ";" + (feedback.note != null ? feedback.note.replace(/(\r\n\t|\n|\r\t)/gm, "") : "") + ";" + feedback.work + ";";
              if (feedback.damage) {
                csvFileContents += "" + feedback.damage.name;
              }
              csvFileContents += ";" + (feedback.feedbackPictures && report.feedback.feedbackPictures.length) + ";;";
            }

            csvFileContents += ";;;;;";

            csvFileContents += "\n";

            report.reportsMeasures.forEach(function (reportMeasure) {
              csvFileContents += ";;;;;;;;;;;;;;";
              csvFileContents += reportMeasure.measure.name + ";";
              csvFileContents += (reportMeasure.surface ? reportMeasure.surface : "") + ";" + (reportMeasure.length ? reportMeasure.length : "") + ";" + (reportMeasure.width ? reportMeasure.width : "") + ";" + (reportMeasure.amount ? reportMeasure.amount : "") + ";" + (reportMeasure.hours ? reportMeasure.hours : "") + ";";
              csvFileContents += ";;;;;;;";
              csvFileContents += "\n";
            });

            if (feedback) {
              feedback.feedbackMeasures.forEach(function (feedbackMeasure) {
                csvFileContents += ";;;;;;;;;;;;;;;;;;;;;;;;;;";
                csvFileContents += feedbackMeasure.measure.name + ";";
                csvFileContents += (feedbackMeasure.surface ? ("" + feedbackMeasure.surface).replace(".", ",") : "") + ";" + (feedbackMeasure.length ? ("" + feedbackMeasure.length).replace(".", ",") : "") + ";" + (feedbackMeasure.width ? ("" + feedbackMeasure.width).replace(".", ",") : "") + ";" + (feedbackMeasure.amount ? ("" + feedbackMeasure.amount).replace(".", ",") : "") + ";" + (feedbackMeasure.hours ? ("" + feedbackMeasure.hours).replace(".", ",") : "");

                csvFileContents += "\n";
              });
            }
          });

          var blob = new Blob([csvFileContents], {
            type: "text/csv;charset=utf-8;"
          });

          var a = document.createElement("a");
          document.body.appendChild(a);
          var url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = new Date().getTime() + ".csv";
          a.click();
          window.URL.revokeObjectURL(url);
        });
      },
      transitionToDetailView: function transitionToDetailView(record) {
        this.transitionToRoute("reports.view", record.id);
      },
      multipleUpdate: function multipleUpdate(value) {
        var checkedUuids = this.get("checkedUuids");
        var status = value;
        var self = this;
        var currentInspection = this.get("currentInspection");
        var company_id = this.get("currentCompany").id;
        var variables = { inspection_id: currentInspection.id, company_id: company_id };

        var promises = checkedUuids.map(function (record) {
          var variables = { id: record.id, status: status };
          return self.get("apollo").mutate({
            mutation: _updateReport.default,
            variables: variables
          });
        });

        Ember.RSVP.all(promises).then(function () {
          self.get("apollo").query({
            query: _reportsIndex.default,
            variables: variables,
            fetchPolicy: 'network-only'
          }).then(function (result) {
            // Changed to regular function
            self.set('model', result); // Use self instead of this                    
            self.set('allChecked', false); // Uncheck header checkbox
          });
        });
      },
      multipleDelete: function multipleDelete() {
        var checkedUuids = this.get("checkedUuids");
        var self = this;
        var currentInspection = this.get("currentInspection");
        var company_id = this.get("currentCompany").id;
        var answer = confirm("Weet je zeker dat je " + checkedUuids.length + " rapporten wilt verwijderen?");

        if (answer) {
          var refetchQueries = ['reports-index', { query: _reportsIndex.default, variables: { inspection_id: currentInspection.id, company_id: company_id }, fetchPolicy: "no-cache" }];

          checkedUuids.forEach(function (record) {
            var variables = { id: record.id };

            self.get("apollo").mutate({
              mutation: _deleteReport.default,
              variables: variables,
              refetchQueries: refetchQueries
            }).then(function (data) {
              if (data.deleteReport.errors != null) {
                self.set("flashMessage", "Deze maatregel wordt gebruikt in een rapport en kan daarom niet worden verwijderd.");
              }
              self.set("deleteErrorMessage", false);
            }).catch(function (error) {
              self.set("editErrorMessage", error);
            });
          });
        }
      }
    }
  });
});