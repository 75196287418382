define("strada-gui/routes/shared/show", ["exports", "ember-apollo-client/mixins/route-query-manager", "strada-gui/gql/queries/inspection-share"], function (exports, _routeQueryManager, _inspectionShare) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_routeQueryManager.default, {
    breadCrumb: {},

    model: function model(params) {
      var variables = { id: params.inspection_share_id };
      return this.get('apollo').watchQuery({ query: _inspectionShare.default, variables: variables, fetchPolicy: "no-cache" }, "inspectionShare");
    },


    renderTemplate: function renderTemplate() {
      this.render('shared.show', {
        into: 'application'
      });
    },

    afterModel: function afterModel(model) {
      var crumb = { inspectionShow: true, title: model.inspection.name, status: model.inspection.status,
        path: 'inspection-shares.show' };
      this.set('breadCrumb', crumb);
    }
  });
});