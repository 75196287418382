define('strada-gui/routes/reports/edit', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember-apollo-client/mixins/route-query-manager', 'strada-gui/gql/queries/report-edit'], function (exports, _authenticatedRouteMixin, _routeQueryManager, _reportEdit) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _routeQueryManager.default, {
    model: function model(params) {
      var company = this.modelFor('companies.show');
      var variables = { id: params.report_id, company_id: company.id };
      return this.get('apollo').watchQuery({ query: _reportEdit.default, variables: variables, fetchPolicy: "no-cache" });
    },


    renderTemplate: function renderTemplate() {
      this.render('reports.edit', {
        into: 'application'
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var company_id = this.modelFor('companies.show').id;
      var meta = { roadSections: model.roadSections, damages: model.damages, pavements: model.pavements,
        measures: model.measures };

      var inspection = this.modelFor('inspections.show');
      controller.set('meta', meta);
      controller.set('inspection', inspection);
      controller.set('companyId', company_id);

      var breadcrumbs = {
        title: 'Rapport wijzigen',
        text: model.report.address,
        previous: {
          anchor: 'Rapporten',
          route: 'reports.index',
          param: inspection.id
        }
      };

      controller.set('companyId', company_id);
      controller.set('breadcrumbs', breadcrumbs);
    }
  });
});