define("strada-gui/controllers/road-sections/index", ["exports", "strada-gui/gql/mutations/create-road-section", "strada-gui/gql/mutations/update-road-section", "ember-apollo-client/mixins/route-query-manager"], function (exports, _createRoadSection, _updateRoadSection, _routeQueryManager) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_routeQueryManager.default, {
    modelSorting: ['name'],
    modelSorted: Ember.computed.sort('model', 'modelSorting'),
    newDialog: false,
    editDialog: false,
    newErrorMessage: false,
    editErrorMessage: false,

    actions: {
      closeNewDialog: function closeNewDialog() {
        this.set('newDialog', false);
      },
      closeEditDialog: function closeEditDialog() {
        this.set('editDialog', false);
      },
      showNewDialog: function showNewDialog() {
        this.set('newDialog', true);
        this.set('newRoadSection', Ember.Object.create({ name: '' }));
      },
      showEditDialog: function showEditDialog(record) {
        this.set('editDialog', true);
        this.set('newRoadSection', Ember.Object.create(record));
      },
      create: function create() {
        var newRoadSection = this.get('newRoadSection');
        var self = this;

        var variables = { 'name': newRoadSection.get('name') };
        this.get("apollo").mutate({ mutation: _createRoadSection.default, variables: variables, refetchQueries: ['roadSections'] }).then(function () {
          self.set('newErrorMessage', false);
          self.set('newDialog', false);
        }).catch(function (error) {
          self.set('newErrorMessage', error);
        });
      },
      update: function update() {
        var newRoadSection = this.get('newRoadSection');
        var self = this;
        var variables = { 'id': newRoadSection.get('id'), 'name': newRoadSection.get('name') };
        this.get("apollo").mutate({ mutation: _updateRoadSection.default, variables: variables, refetchQueries: ['roadSections'] }).then(function () {
          self.set('editErrorMessage', false);
          self.set('editDialog', false);
        }).catch(function (error) {
          self.set('editErrorMessage', error);
        });
      }
    }
  });
});