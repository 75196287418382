define('strada-gui/routes/companies/index', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember-apollo-client/mixins/route-query-manager', 'strada-gui/gql/queries/companies'], function (exports, _authenticatedRouteMixin, _routeQueryManager, _companies) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _routeQueryManager.default, {
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),

    model: function model() {
      return this.get('apollo').watchQuery({ query: _companies.default, fetchPolicy: "no-cache" }, "companies");
    },


    afterModel: function afterModel(companies) {
      var role = this.get('currentUser.user').role;

      if (role === 'CONTRACTOR') {
        this.transitionTo('splash');
      } else {
        if (companies.get('length') === 1) {
          this.transitionTo('companies.show', companies.get('firstObject'));
        }
      }
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.controllerFor('application').set('currentCompany', null);

      var breadcrumbs = {
        title: 'Bedrijven'
      };

      controller.set('breadcrumbs', breadcrumbs);
    }
  });
});