define("strada-gui/controllers/shared/show", ["exports", "ember-apollo-client/mixins/route-query-manager", "strada-gui/gql/mutations/update-shared-report"], function (exports, _routeQueryManager, _updateSharedReport) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_routeQueryManager.default, {
    reportsSorting: ['position:asc'],
    sortedReports: Ember.computed.sort('model.reports', 'reportsSorting'),
    mapView: false,
    bounds: null,

    boundsComputed: function () {
      var reports = this.get('model.reports');
      var coordinates = [];

      reports.forEach(function (item) {
        if (item.latitude && item.longitude) {
          coordinates.push([item.latitude, item.longitude]);
        }
      });

      if (coordinates.length === 0) {
        coordinates.push([53.530986, 7.177372]);
        coordinates.push([50.873502, 3.355695]);
      }

      this.set('bounds', L.latLngBounds(coordinates));
    }.observes('model.reports'),

    actions: {
      update: function update(record) {
        var model = this.get('model');
        var status = record.status == 'open' ? 'solved' : 'open';
        var variables = {
          inspectionShareId: model.id,
          reportId: record.id,
          status: status
        };
        this.get("apollo").mutate({
          mutation: _updateSharedReport.default,
          variables: variables,
          refetchQueries: ['inspectionShare']
        });
      },
      toggleMapView: function toggleMapView() {
        this.toggleProperty('mapView');
      },
      goToRoute: function goToRoute(report) {
        this.transitionToRoute('reports.show', report.id);
      }
    }
  });
});