define('strada-gui/components/feedback-pictures', ['exports', 'strada-gui/gql/mutations/delete-feedback-picture', 'ember-apollo-client/mixins/component-query-manager'], function (exports, _deleteFeedbackPicture, _componentQueryManager) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_componentQueryManager.default, {
    classNames: ['report-pictures'],

    init: function init() {
      this._super.apply(this, arguments);
      this.set('activeIndex', 0);
      this.set('activePicture', null);
    },


    pictureCount: Ember.computed('feedbackModel.feedbackPictures.@each', function () {
      return this.get('feedbackModel.feedbackPictures.length');
    }),

    actions: {
      showLightbox: function showLightbox(fileUrl) {
        this.set('lightboxModal', true);
        this.set('activePicture', fileUrl);
      },
      hideLightbox: function hideLightbox() {
        this.set('lightboxModal', false);
        this.set('activePicture', null);
      },
      slidePrev: function slidePrev() {
        var activeIndex = this.get('activeIndex');
        var pictureCount = this.get('pictureCount');
        var newIndex = activeIndex > 0 ? activeIndex - 1 : pictureCount - 1;

        this.set('activeIndex', newIndex);
      },
      slideNext: function slideNext() {
        var activeIndex = this.get('activeIndex') + 1;
        var pictureCount = this.get('pictureCount');
        var newIndex = activeIndex < pictureCount ? activeIndex : 0;

        this.set('activeIndex', newIndex);
      },
      destroyPicture: function destroyPicture() {
        var activeIndex = this.get('activeIndex');
        var picture = Ember.$('.picture[data-index=\'' + activeIndex + '\']');
        var picture_id = picture.data('id');

        var variables = { id: picture_id };
        this.get("apollo").mutate({ mutation: _deleteFeedbackPicture.default, variables: variables, refetchQueries: ['report', 'reports-csv'] });

        Ember.$('.picture').removeClass('active');
        Ember.$('.picture:first-child').addClass('active');
      },
      create: function create(fileThumbUrl, fileName) {
        var feedbackModel = this.get('feedbackModel');

        feedbackModel.feedbackPictures.unshiftObject({
          "fileThumbUrl": fileThumbUrl,
          "fileName": fileName,
          "temp": true
        });
        this.send('setFeedbackPictures');
      },
      delete: function _delete(index) {
        var feedbackModel = this.get('feedbackModel');
        feedbackModel.feedbackPictures.popObject(feedbackModel.feedbackPictures[index]);
        this.send('setFeedbackPictures');
      },
      setFeedbackPictures: function setFeedbackPictures() {
        var feedbackModel = this.get('feedbackModel');
        this.setFeedbackPictures(feedbackModel.feedbackPictures);
      }
    }
  });
});