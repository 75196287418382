define('strada-gui/routes/inspections/map', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember-apollo-client/mixins/route-query-manager', 'strada-gui/gql/queries/reports-map', 'strada-gui/gql/queries/inspection'], function (exports, _authenticatedRouteMixin, _routeQueryManager, _reportsMap, _inspection) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _routeQueryManager.default, {
    model: function model(params) {
      var inspectionId = params.inspection_id;
      this.set('inspectionId', inspectionId);
      var variables = {
        inspection_id: inspectionId
      };
      return this.get('apollo').watchQuery({
        query: _reportsMap.default,
        variables: variables
      });
    },


    renderTemplate: function renderTemplate() {
      this.render('inspections.map', {
        into: 'application'
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var inspectionId = this.get('inspectionId');
      var variables = {
        id: inspectionId
      };

      this.get('apollo').watchQuery({
        query: _inspection.default,
        variables: variables
      }, "inspection").then(function (inspection) {
        controller.set('currentInspection', inspection);

        var breadcrumbs = {
          title: 'Kaart',
          text: inspection.name,
          previous: {
            anchor: 'Inspecties',
            route: 'inspections'
          }
        };

        controller.set('breadcrumbs', breadcrumbs);
      });
    }
  });
});