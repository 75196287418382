define("strada-gui/gql/fragments/feedback-fragment", ["exports", "strada-gui/gql/fragments/user-fragment", "strada-gui/gql/fragments/feedback-measure-fragment", "strada-gui/gql/fragments/feedback-picture-fragment", "strada-gui/gql/fragments/damage-fragment"], function (exports, _userFragment, _feedbackMeasureFragment, _feedbackPictureFragment, _damageFragment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var doc = {
    "kind": "Document",
    "definitions": [{
      "kind": "FragmentDefinition",
      "name": {
        "kind": "Name",
        "value": "FeedbackFragment"
      },
      "typeCondition": {
        "kind": "NamedType",
        "name": {
          "kind": "Name",
          "value": "Feedback"
        }
      },
      "directives": [],
      "selectionSet": {
        "kind": "SelectionSet",
        "selections": [{
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "id"
          },
          "arguments": [],
          "directives": []
        }, {
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "material"
          },
          "arguments": [],
          "directives": []
        }, {
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "note"
          },
          "arguments": [],
          "directives": []
        }, {
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "work"
          },
          "arguments": [],
          "directives": []
        }, {
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "user"
          },
          "arguments": [],
          "directives": [],
          "selectionSet": {
            "kind": "SelectionSet",
            "selections": [{
              "kind": "FragmentSpread",
              "name": {
                "kind": "Name",
                "value": "UserFragment"
              },
              "directives": []
            }]
          }
        }, {
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "feedbackMeasures"
          },
          "arguments": [],
          "directives": [],
          "selectionSet": {
            "kind": "SelectionSet",
            "selections": [{
              "kind": "FragmentSpread",
              "name": {
                "kind": "Name",
                "value": "FeedbackMeasureFragment"
              },
              "directives": []
            }]
          }
        }, {
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "feedbackPictures"
          },
          "arguments": [],
          "directives": [],
          "selectionSet": {
            "kind": "SelectionSet",
            "selections": [{
              "kind": "FragmentSpread",
              "name": {
                "kind": "Name",
                "value": "FeedbackPictureFragment"
              },
              "directives": []
            }]
          }
        }, {
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "report"
          },
          "arguments": [],
          "directives": [],
          "selectionSet": {
            "kind": "SelectionSet",
            "selections": [{
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "id"
              },
              "arguments": [],
              "directives": []
            }]
          }
        }, {
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "damage"
          },
          "arguments": [],
          "directives": [],
          "selectionSet": {
            "kind": "SelectionSet",
            "selections": [{
              "kind": "FragmentSpread",
              "name": {
                "kind": "Name",
                "value": "DamageFragment"
              },
              "directives": []
            }]
          }
        }]
      }
    }],
    "loc": {
      "start": 0,
      "end": 499
    }
  };
  exports.default = doc;

  doc.definitions = doc.definitions.concat(_userFragment.default.definitions);

  doc.definitions = doc.definitions.concat(_feedbackMeasureFragment.default.definitions);

  doc.definitions = doc.definitions.concat(_feedbackPictureFragment.default.definitions);

  doc.definitions = doc.definitions.concat(_damageFragment.default.definitions);
});