define('strada-gui/authenticators/custom', ['exports', 'ember-simple-auth/authenticators/base', 'strada-gui/config/environment', 'strada-gui/gql/mutations/sign-in'], function (exports, _base, _environment, _signIn) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    apollo: Ember.inject.service(),
    serverTokenEndpoint: _environment.default.host + '/ui/authentication/sign-in',

    restore: function restore(data) {
      if (!Ember.isEmpty(data.token)) {
        return Ember.RSVP.Promise.resolve(data);
      } else {
        return Ember.RSVP.Promise.reject();
      }
    },
    authenticate: function authenticate(email, password) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var variables = { email: email, password: password };
        return _this.get("apollo").mutate({ mutation: _signIn.default, variables: variables }, "signIn").then(function (response) {
          if (response == null) {
            Ember.run(null, reject);
          } else {
            Ember.run(null, resolve, response);
          }
        }, function (xhr) {
          return Ember.run(null, reject, xhr.responseJSON || xhr.responseText);
        });
      });
    }
  });
});