define("strada-gui/gql/queries/inspections", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var doc = {
    "kind": "Document",
    "definitions": [{
      "kind": "OperationDefinition",
      "operation": "query",
      "name": {
        "kind": "Name",
        "value": "inspections"
      },
      "variableDefinitions": [{
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "list_inspections"
          }
        },
        "type": {
          "kind": "NonNullType",
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "ListInspectionsInput"
            }
          }
        }
      }, {
        "kind": "VariableDefinition",
        "variable": {
          "kind": "Variable",
          "name": {
            "kind": "Name",
            "value": "pagination"
          }
        },
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "PaginationInput"
          }
        }
      }],
      "directives": [],
      "selectionSet": {
        "kind": "SelectionSet",
        "selections": [{
          "kind": "Field",
          "name": {
            "kind": "Name",
            "value": "inspections"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "listInspections"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "list_inspections"
              }
            }
          }, {
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "pagination"
            },
            "value": {
              "kind": "Variable",
              "name": {
                "kind": "Name",
                "value": "pagination"
              }
            }
          }],
          "directives": [],
          "selectionSet": {
            "kind": "SelectionSet",
            "selections": [{
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "entries"
              },
              "arguments": [],
              "directives": [],
              "selectionSet": {
                "kind": "SelectionSet",
                "selections": [{
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "id"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "name"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "status"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "executionAt"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "reportCount"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "alias": {
                    "kind": "Name",
                    "value": "reportCountOpen"
                  },
                  "name": {
                    "kind": "Name",
                    "value": "reportStatusCount"
                  },
                  "arguments": [{
                    "kind": "Argument",
                    "name": {
                      "kind": "Name",
                      "value": "status"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "OPEN"
                    }
                  }],
                  "directives": []
                }, {
                  "kind": "Field",
                  "alias": {
                    "kind": "Name",
                    "value": "reportCountAssigned"
                  },
                  "name": {
                    "kind": "Name",
                    "value": "reportStatusCount"
                  },
                  "arguments": [{
                    "kind": "Argument",
                    "name": {
                      "kind": "Name",
                      "value": "status"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "ASSIGNED"
                    }
                  }],
                  "directives": []
                }, {
                  "kind": "Field",
                  "alias": {
                    "kind": "Name",
                    "value": "reportCountSolved"
                  },
                  "name": {
                    "kind": "Name",
                    "value": "reportStatusCount"
                  },
                  "arguments": [{
                    "kind": "Argument",
                    "name": {
                      "kind": "Name",
                      "value": "status"
                    },
                    "value": {
                      "kind": "EnumValue",
                      "value": "SOLVED"
                    }
                  }],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "user"
                  },
                  "arguments": [],
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [{
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "id"
                      },
                      "arguments": [],
                      "directives": []
                    }, {
                      "kind": "Field",
                      "name": {
                        "kind": "Name",
                        "value": "name"
                      },
                      "arguments": [],
                      "directives": []
                    }]
                  }
                }]
              }
            }, {
              "kind": "Field",
              "name": {
                "kind": "Name",
                "value": "pagination"
              },
              "arguments": [],
              "directives": [],
              "selectionSet": {
                "kind": "SelectionSet",
                "selections": [{
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "currentPage"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "totalPages"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "nextPage"
                  },
                  "arguments": [],
                  "directives": []
                }, {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "previousPage"
                  },
                  "arguments": [],
                  "directives": []
                }]
              }
            }]
          }
        }]
      }
    }],
    "loc": {
      "start": 0,
      "end": 578
    }
  };
  exports.default = doc;
});