define('strada-gui/components/report-map', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['report-map'],

    location: Ember.computed('model.latitude', 'model.longitude', function () {
      return [this.get('model').latitude, this.get('model').longitude];
    }),

    divIcon: Ember.computed('model.address', 'location', function () {
      var position = this.get('model').position ? this.get('model').position : 0;
      return '<div class="hint--top hint--rounded" aria-label="' + this.get('model').address + '"><div class="icon-marker"></div><div class=\'position\'>' + position + '</div></div>';
    }),

    getAddressComponent: function getAddressComponent(data, name) {
      var address = data['results'][0]['address_components'];
      var result = address.find(function (component) {
        return component.types.includes(name);
      });

      return result ? result.long_name : null;
    },


    actions: {
      onDragend: function onDragend(data) {
        var model = this.get('model');
        Ember.set(model, 'latitude', data.target._latlng.lat);
        Ember.set(model, 'longitude', data.target._latlng.lng);
        var requestEndpoint = ' https://nominatim.openstreetmap.org/reverse';
        var self = this;

        Ember.$.ajax({ type: "GET", url: requestEndpoint,
          data: { format: 'json', lat: model.latitude, lon: model.longitude }
        }).then(function (data) {
          var house_number = data.address.house_number ? ' ' + data.address.house_number : '';
          var street = void 0;

          if (data.address.road) {
            street = data.address.road;
          } else if (data.address.pedestrian) {
            street = data.address.pedestrian;
          } else {
            street = '';
          }

          var city = data.address.city;
          var address = street + house_number;
          Ember.set(model, 'city', city);
          Ember.set(model, 'address', address);
          self.setLocation(city, address);
        });
      }
    }
  });
});