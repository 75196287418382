define('strada-gui/routes/application', ['exports', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _applicationRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service;
  exports.default = Ember.Route.extend(_applicationRouteMixin.default, {
    moment: service(),
    currentUser: service(),

    beforeModel: function beforeModel() {
      this.get('moment').setTimeZone('Europe/Amsterdam');
      this.get('moment').setLocale('nl');
      return this._loadCurrentUser();
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('currentCompany', this.modelFor('companies.show'));
    },
    sessionAuthenticated: function sessionAuthenticated() {
      this._super.apply(this, arguments);
      this._loadCurrentUser();
    },
    _loadCurrentUser: function _loadCurrentUser() {
      var _this = this;

      return this.get('currentUser').load().catch(function () {
        return _this.get('session').invalidate();
      });
    }
  });
});