define("strada-gui/controllers/contractor-projects/index", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ["page"],
    page: 1,

    actions: {
      selectPage: function selectPage(page) {
        window.scroll(0, 0);
        this.set("page", page);
      }
    }
  });
});