define('strada-gui/components/report-show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    location: Ember.computed('model.latitude', 'model.longitude', function () {
      return [this.get('model').latitude, this.get('model').longitude];
    }),

    divIcon: Ember.computed('location', function () {
      return '<div class="hint--top hint--rounded" aria-label="' + this.get('model').address + '"><div class="icon-marker"></div><div class=\'position\'>' + this.get('model').position + '</div></div>';
    }),

    actions: {
      setActive: function setActive(pictureName) {
        Ember.$('.picture').removeClass('active');
        Ember.$('.picture[data-name="' + pictureName + '"]').addClass('active');
      }
    }
  });
});