define('strada-gui/routes/contractor-reports/index', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember-apollo-client/mixins/route-query-manager', 'strada-gui/gql/queries/project-reports'], function (exports, _authenticatedRouteMixin, _routeQueryManager, _projectReports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _routeQueryManager.default, {
    queryParams: {
      pavementId: { refreshModel: true },
      roadSectionId: { refreshModel: true },
      damageId: { refreshModel: true },
      measureId: { refreshModel: true },
      status: { refreshModel: true },
      query: { refreshModel: true }
    },

    model: function model(params) {
      this.set('params', params);
      var contractorProject = this.modelFor('contractor-projects.show');
      this.set('currentProjectId', contractorProject.contractor_project_id);
      var variables = { project_id: contractorProject.contractor_project_id, measure_id: params.measureId,
        pavement_id: params.pavementId, road_section_id: params.roadSectionId, damage_id: params.damageId,
        status: params.status, query: params.query };
      for (var key in variables) {
        if (variables[key] == "") delete variables[key];
      }
      return this.get('apollo').watchQuery({ query: _projectReports.default, variables: variables, fetchPolicy: "no-cache" });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('currentProjectId', this.get('currentProjectId'));

      var currentInspection = void 0;
      if (model.projectReports.length) {
        currentInspection = model.projectReports.firstObject.project.inspection;
        controller.set('currentInspection', currentInspection);
      }

      var breadcrumbs = {
        title: 'Rapporten',
        text: currentInspection ? currentInspection.name : '',
        previous: {
          anchor: 'Opdrachten',
          route: 'contractor-projects'
        }
      };

      controller.set('breadcrumbs', breadcrumbs);
      model.reports = model.projectReports;
      controller.set('reports', model);

      model.measures = model.projectMeasures;
      controller.set('measures', model);
    }
  });
});