define("strada-gui/components/report-measures", ["exports", "ember-apollo-client/mixins/component-query-manager", "strada-gui/gql/queries/measures"], function (exports, _componentQueryManager, _measures) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Component.extend(_componentQueryManager.default, {
    classNames: ['report-measures'],

    init: function init() {
      this._super.apply(this, arguments);
      var editMode = this.get('editMode');

      if (editMode) {
        var companyId = this.get('companyId');
        var variables = { company_id: companyId };
        var meta = { measures: this.get('apollo').watchQuery({ query: _measures.default, variables: variables, fetchPolicy: "no-cache" }, "measures") };
        this.set('currentIndex', false);
        this.set('meta', meta);
        this.set('newDialog', false);
        this.set('editDialog', false);
        this.set('editModel', false);
      }
    },


    actions: {
      closeNewDialog: function closeNewDialog() {
        this.set('newDialog', false);
      },
      closeEditDialog: function closeEditDialog() {
        this.set('editDialog', false);
      },
      showEditDialog: function showEditDialog(index) {
        var reportsMeasures = this.get('model.reportsMeasures');
        var reportsMeasure = reportsMeasures[index];

        this.set('currentIndex', index);
        this.set('editModel', reportsMeasure);
        this.set('editDialog', true);
      },
      showNewDialog: function showNewDialog() {
        var model = this.get('model');
        this.set('newModel', { reportId: model.id });
        this.set('newDialog', true);
      },
      setMeasureId: function setMeasureId(value) {
        var newDialog = this.get('newDialog');
        var objectName = newDialog ? 'newModel' : 'editModel';

        this.set(objectName + ".measure", value);
        this.set(objectName + ".measureId", value.id);
      },
      create: function create() {
        var variables = this.get('newModel');
        variables = convertFloats(variables, ['amount', 'length', 'hours', 'surface', 'width']);
        var reportMeasures = this.get('model.reportsMeasures');
        reportMeasures.pushObject(variables);
        this.send('closeNewDialog');
      },
      delete: function _delete(index) {
        var reportsMeasures = this.get('model.reportsMeasures');
        reportsMeasures.removeAt(index);
        var editModel = this.get('editModel');

        if (editModel) {
          this.set('editDialog', false);
        }
      },
      update: function update() {
        var editModel = Ember.Object.create(this.get('editModel'));
        editModel = convertFloats(editModel, ['amount', 'length', 'hours', 'surface', 'width']);
        this.set('editModel', editModel);
        this.send('closeEditDialog');
      }
    }
  });


  function convertFloats(object, properties) {
    var floats = Ember.getProperties(object, properties);

    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = Object.entries(floats)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var _ref = _step.value;

        var _ref2 = _slicedToArray(_ref, 2);

        var key = _ref2[0];
        var value = _ref2[1];

        var parsedValue = parseFloat(value);
        floats[key] = isNaN(parsedValue) ? null : parsedValue;
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    return Object.assign(object, floats);
  }
});