define("strada-gui/routes/projects/show", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "ember-apollo-client/mixins/route-query-manager", "strada-gui/gql/queries/project-reports"], function (exports, _authenticatedRouteMixin, _routeQueryManager, _projectReports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, _routeQueryManager.default, {
    model: function model(params) {
      this.set("params", params);

      var variables = { project_id: params.project_id };
      return this.get("apollo").watchQuery({ query: _projectReports.default, variables: variables, fetchPolicy: "no-cache" }, "projectReports");
    },


    renderTemplate: function renderTemplate() {
      this.render("projects.show", {
        into: "application"
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set("reports", model);
      var breadcrumbs = {
        title: "Rapporten",
        previous: {
          anchor: "Opdrachten",
          route: "projects"
        }
      };

      controller.set("breadcrumbs", breadcrumbs);
    }
  });
});