define('strada-gui/helpers/current-date', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.currentDate = currentDate;
  function currentDate() {
    return (0, _moment.default)().format('LL');
  }

  exports.default = Ember.Helper.helper(currentDate);
});